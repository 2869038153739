import axios, { AxiosError, AxiosResponse, Cancel } from 'axios';
import { toast } from 'react-toastify';
import { history } from '../..';
import { CancelMarketingDTO, DeleteIntroDTO, DeleteRepairShopDTO, DeleteRestingPlaceDTO, DetailMarketingDTO, IntroCardDTO, IntroRecommendationDTO, IntroVideoDTO, NotificationDTO, MarketingDTO, ProductDTO, RepairShopDTO, RepairShopDetailDTO, RestingPlaceDTO, UpdateOrderDTO, UpdateRetrofitDTO, NotificationDetailDTO, RemoveNotificationDTO, SystemNotificationDTO, RemoveSystemNotificationDTO, SystemNotificationDetailDTO, ActivityDTO, DeleteActivityDTO, StarPointDetailDTO, StarPointListDTO, LeaderboardList } from '../models/models';
import { PaginatedResult } from '../models/pagination';
import { ServerError } from '../models/serverError';
import { CodeInfo, User, UserFormValues, UserSearchListItem } from '../models/user';
import { store } from '../stores/store';


axios.defaults.baseURL = process.env.REACT_APP_API_URL + 'api';
axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if (token) config.headers.Authorization = `Bearer ${token}`
    config.withCredentials = true;
    return config;
})


axios.interceptors.response.use(async response => {
    const pagination = response.headers['pagination'];
    if (pagination) {
        response.data = new PaginatedResult(response.data, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResult<any>>
    }
    return response;
}, (error: AxiosError) => {
    if (error.response) {
        const { data, status, config } = error.response!;
        switch (status) {
            case 400:
                if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
                    history.push('/');
                    toast.error(data);
                }
                if (data.errors) {
                    const modalStateErrors = [];
                    for (const key in data.errors) {
                        if (data.errors[key]) {
                            modalStateErrors.push(data.errors[key])
                        }
                    }
                    toast.error(modalStateErrors.flat().toString());
                    throw modalStateErrors.flat();
                } else {
                    toast.error(data);
                }
                break;
            case 429:
                toast.error('Ardarda çok fazla istek yapıldı. Lütfen daha sonra tekrar deneyin.');
                break;
            case 401:
                if (error.response && typeof error.response.data === 'string' && error.response.data.trim().length > 0) {
                    toast.error(error.response.data)
                }
                store.userStore.logout();
                history.push('/');
                break;
            case 404:
                history.push('/not-found');
                break;
            case 500:
                store.commonStore.setServerError(data);
                history.push('/server-error');
                break;
            default:
                toast.error("Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.");
                break;
        }
    }
    else {
        let detail: string = '';


        if (error.message.trim().toUpperCase() === "NETWORK ERROR") {
            detail = 'Ağ bağlantısında problem oluştu!'
        }
        else {
            detail = error.stack ? error.stack : '';

        }

        const errM: ServerError = {
            statusCode: 99,
            message: error.message,
            details: detail
        };

        store.commonStore.setServerError(errM);
        history.push('/server-error');
        toast.error(error.message);
    }
    return Promise.reject(error);
})

const responseBody = <T>(response: AxiosResponse<T>) => response.data;
const responseStatus = <T>(response: AxiosResponse<T>) => response.status;

const requests = {

    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    postwStatus: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseStatus),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
    download: <BLob>(url: string, body: {}, responseType: any) => axios.post<Blob>(url, body).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.csv');
        document.body.appendChild(link);
        link.click();
    })


}

const AppState = {
    getAppState: () => requests.get('/mobile-app-state/getState'),
    setAppStateOnline: (state: boolean) => requests.postwStatus<void>('/mobile-app-state/online', state),
    setAppStateOffline: (state: boolean) => requests.postwStatus<void>('/mobile-app-state/offline', state),
}



const IntroCard = {
    listCards: () => requests.get<IntroCardDTO[]>('/IntroCard/ListIntroCards'),
    detail: (accountId: string) => requests.get<IntroCardDTO>(`/IntroCard/IntroCardDetail/${accountId}`),
    createCard: (card: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/IntroCard/CreateIntroCard', card, config);
    },
    updateCard: (card: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/IntroCard/UpdateIntroCard', card, config);
    },


}

const IntroVideo = {
    listVideos: () => requests.get<IntroVideoDTO[]>('/IntroVideo/ListVideos'),
    detail: (accountId: string) => requests.get<IntroVideoDTO>(`/IntroVideo/IntroVideoDetail/${accountId}`),
    createVideo: (video: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/IntroVideo/CreateIntroVideo', video, config);
    },
    updateVideo: (video: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/IntroVideo/UpdateIntroVideo', video, config);
    },
}

const IntroRecommendation = {
    listRecommendations: () => requests.get<IntroRecommendationDTO[]>('/IntroRecommendation/ListRecommendation'),
    detail: (accountId: string) => requests.get<IntroRecommendationDTO>(`/IntroRecommendation/IntroRecommendationDetail/${accountId}`),
    createRecommendation: (recommendation: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/IntroRecommendation/CreateIntroRecommendation', recommendation, config);
    },
    updateRecommendation: (recommendation: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/IntroRecommendation/UpdateIntroRecommendation', recommendation, config);
    },
}

const Intros = {
    deleteIntro: (selectedIntro: DeleteIntroDTO) => requests.postwStatus<void>('/Intro/DeleteIntro', selectedIntro),

}

const RestingPlaces = {
    list: () => requests.get<RestingPlaceDTO[]>('/RestingPlace/ListRestingPlaces'),
    delete: (selectedRestinPlace: DeleteRestingPlaceDTO) => requests.postwStatus<void>('/RestingPlace/DeleteRestingPlace', selectedRestinPlace),
    detail: (accountId: string) => requests.get<RestingPlaceDTO>(`/RestingPlace/DetailRestingPlace/${accountId}`),
    getRPServices: () => requests.get<RestingPlaceDTO[]>('/RestingPlace/ListRestingPlaces'),
    create: (restingPlace: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/RestingPlace/CreateRestingPlace', restingPlace, config);
    },

    update: (restingPlace: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/RestingPlace/UpdateRestingPlace', restingPlace, config);
    },
}

const RepairShops = {
    list: () => requests.get<RepairShopDTO[]>('/RepairShop/list'),
    delete: (selectedRestinPlace: CancelMarketingDTO) => requests.postwStatus<void>('/RepairShop/delete', selectedRestinPlace),
    detail: (accountId: string) => requests.get<RepairShopDetailDTO>(`/RepairShop/detail/${accountId}`),
    create: (repairShop: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/RepairShop/create', repairShop, config);
    },

    update: (repairShop: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/RepairShop/update', repairShop, config);
    },
}


const Story = {
    list: () => requests.get<MarketingDTO[]>('/story/list'),
    detail: (accountId: string) => requests.get<DetailMarketingDTO>(`/story/detail/${accountId}`),
    cancelStory: (story: CancelMarketingDTO) => requests.postwStatus('/story/cancel', story),
    exportStory: (story: CancelMarketingDTO) => requests.download('/story/export', story, new Blob),
    createStory: (story: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/story/create', story, config);
    },
    excelExport: (postUrl: string, postId: string, filename: string) => axios.request({
        method: 'POST', url: axios.defaults.baseURL + postUrl,
        data: { id: postId }, responseType: 'blob'
    })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename + '.xlsx');
            document.body.appendChild(link);
            link.click();
        })
}

const Campaign = {
    list: () => requests.get<MarketingDTO[]>('/campaign/list'),
    detail: (accountId: string) => requests.get<DetailMarketingDTO>(`/campaign/detail/${accountId}`),
    cancelCampaign: (campaign: CancelMarketingDTO) => requests.postwStatus('/campaign/cancel', campaign),
    createCampaign: (campaign: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/campaign/create', campaign, config);
    },
    excelExport: (postUrl: string, postId: string, filename: string) => axios.request({
        method: 'POST', url: axios.defaults.baseURL + postUrl,
        data: { id: postId }, responseType: 'blob'
    })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename + '.xlsx');
            document.body.appendChild(link);
            link.click();
        })
}
const CompanyNew = {
    list: () => requests.get<MarketingDTO[]>('/company-new/list'),
    detail: (accountId: string) => requests.get<DetailMarketingDTO>(`/company-new/detail/${accountId}`),
    cancelCompanyNew: (companyNew: CancelMarketingDTO) => requests.postwStatus('/company-new/cancel', companyNew),
    createCompanyNew: (companyNew: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/company-new/create', companyNew, config);
    },
    updateOrder: (companyNew: UpdateOrderDTO) => requests.postwStatus('/company-new/update-order', companyNew),
}

const Product = {
    list: () => requests.get<ProductDTO[]>('/Product/list'),
    detail: (accountId: string) => requests.get<ProductDTO>(`/Product/detail/${accountId}`),
    delete: (product: CancelMarketingDTO) => requests.postwStatus('/Product/delete', product),
    createProduct: (product: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/Product/create', product, config);
    },

    updateRetrofit: (retrofit: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/Product/update-retrofit', retrofit, config);
    },
    updateRetrofit2: (retrofit: UpdateRetrofitDTO) => requests.postwStatus('/Product/update-retrofit', retrofit),
    updateOrder: (product: UpdateOrderDTO) => requests.postwStatus('/Product/update-order', product),
}


const Notification = {
    list: () => requests.get<NotificationDTO[]>('/Notification/list'),
    detail: (accountId: string) => requests.get<NotificationDetailDTO>(`/Notification/detail/${accountId}`),
    delete: (notification: RemoveNotificationDTO) => requests.postwStatus('/Notification/delete', notification),
    create: (notification: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/Notification/create', notification, config);
    },
    excelExport: (postUrl: string, postId: string, filename: string) => axios.request({
        method: 'POST', url: axios.defaults.baseURL + postUrl,
        data: { id: postId }, responseType: 'blob'
    })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename + '.xlsx');
            document.body.appendChild(link);
            link.click();
        })
}


const SystemNotification = {
    list: () => requests.get<SystemNotificationDTO[]>('/SystemNotification/list'),
    detail: (accountId: string) => requests.get<SystemNotificationDetailDTO>(`/SystemNotification/detail/${accountId}`),
    delete: (systemNotification: RemoveSystemNotificationDTO) => requests.postwStatus('/SystemNotification/delete', systemNotification),
    create: (systemNotification: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/SystemNotification/create', systemNotification, config);
    },
    update: (systemNotification: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/SystemNotification/update', systemNotification, config);
    },
}


const Activity = {
    list: () => requests.get<ActivityDTO[]>('/Activity/List'),
    delete: (selectedActivity: DeleteActivityDTO) => requests.postwStatus<void>('/Activity/Delete', selectedActivity),
    detail: (activityId: string) => requests.get<ActivityDTO>(`/Activity/Detail/${activityId}`),
    create: (activity: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/Activity/Create', activity, config);
    },

    update: (activity: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/Activity/Update', activity, config);
    },
}

const StarPoint = {
    list: () => requests.get<StarPointListDTO[]>('/points/list'),
    detail: (starPointId: string) => requests.get<StarPointDetailDTO>(`/points/detail/${starPointId}`),
    leaderboards: (periodId: string) => requests.get<LeaderboardList>(`/points/leaderboard/${periodId}`),
    update: (starPoint: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post('/points/update', starPoint, config);
    },
}

const Account = {
    current: () => requests.get<User>('/Account'),
    login: (user: UserFormValues) => requests.post<User>('/Account/login', user),
    openIdLogin: (code: CodeInfo) => requests.post<User>('/Account/OpenIdLogin', code),
    register: (user: UserFormValues) => requests.post<User>('/Account/register', user),
    refreshToken: () => requests.post<User>("/Account/refreshToken", {}),
}

const Users = {
    get: (username: string) => requests.get<UserSearchListItem[]>(`/users/${username}`),
}



const agent = {
    Account,
    Users,
    IntroCard,
    IntroVideo,
    IntroRecommendation,
    Intros,
    RepairShops,
    RestingPlaces,
    Story,
    Campaign,
    Product,
    CompanyNew,
    Notification,
    SystemNotification,
    AppState,
    Activity,
    StarPoint,
}

export default agent;