import { makeAutoObservable } from "mobx";
import { IntroVideoDTO, IntroVideoListDTO } from "../models/models";
import agent from "../api/agent";

export default class IntroVideoStore {

    introVideos: IntroVideoListDTO | undefined = undefined;

    modalLoadingInitial = false;
    historyLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }


    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }
    setHistoryLoadingInitial = (val: boolean) => {
        this.historyLoadingInitial = val;
    }

    loadIntroVideos = async () => {
        try {
            const result = await agent.IntroVideo.listVideos();
            if (result) {
                this.introVideos = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;

        } catch (error) {
            //console.log(error);
            return false;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    getIntroVideoDetail = async (id: string): Promise<IntroVideoDTO | null> => {
        try {
            const result = await agent.IntroVideo.detail(id);
            return result;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    createVideo = async (video: FormData) => {
        try {
            return await agent.IntroVideo.createVideo(video);
        } catch (error) {
            //console.log(error);
            return false;
        }

    }

    updateVideo = async (video: FormData) => {
        try {
            return await agent.IntroVideo.updateVideo(video);
        } catch (error) {
            //console.log(error);
            return false;
        }

    }

}