import { makeAutoObservable } from "mobx";
import { DeleteRestingPlaceDTO, RestingPlaceDTO, RestingPlaceListDTO } from "../models/models";
import agent from "../api/agent";

export default class RestingPlaceStore {

    restingPlaces: RestingPlaceListDTO | undefined = undefined;

    modalLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }

    loadRestingPlaces = async () => {
        try {
            const result = await agent.RestingPlaces.list();
            if (result) {
                this.restingPlaces = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;
        } catch (error) {
            //console.log(error);
            return false;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    delete = async (req: DeleteRestingPlaceDTO) => {
        try {
            var res = await agent.RestingPlaces.delete(req);
            return res;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    getRestingPlaceDetail = async (id: string): Promise<RestingPlaceDTO | null> => {
        try {
            const result = await agent.RestingPlaces.detail(id);
            return result;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    createRestingPlace = async (restingPlace: FormData) => {
        try {
            return await agent.RestingPlaces.create(restingPlace);
        } catch (error) {
            //console.log(error);
            return false;
        }
    }

    updateRestingPlace = async (restingPlace: FormData) => {
        try {
            return await agent.RestingPlaces.update(restingPlace);
        } catch (error) {
            //console.log(error);
            return false;
        }

    }
}