import { useEffect, useState } from "react";
import { Confirm, Dropdown } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { toast } from 'react-toastify'
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from '../../../app/stores/store';
import { NotificationDTO } from '../../../app/models/models';
import ModalInnerContainer from "../../../app/common/modals/ModalInnerContainer";
import { history } from "../../..";
import CreateNotification from "./CreateNotification";
import DetailNotification from "./DetailNotification";

export default observer(function NotificationIndex() {

    const { notificationStore } = useStore();
    const [notifications, setNotifications] = useState<NotificationDTO[]>([]);
    const [filteredNotifications, setFilteredNotifications] = useState<NotificationDTO[]>([]);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [isThereData, setIsThereData] = useState<boolean>(true);

    const [title, setTitle] = useState('');
    const [profile, setProfile] = useState('');
    const [vehicleDropdown, setVehicleDropdown] = useState('');

    const [createNotificationModal, setCreateNotificationModal] = useState<boolean>(false);
    const [detailNotificationModal, setDetailNotificationModal] = useState<boolean>(false);

    const [elementId, setElementId] = useState<string>('');

    const [search, setSearch] = useState('');

    const listPerPage = 5;
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            await notificationStore.loadNotifications();
            if (notificationStore.notifications) {
                setNotifications(JSON.parse(JSON.stringify(notificationStore.notifications)));
                setFilteredNotifications(JSON.parse(JSON.stringify(notificationStore.notifications)));
                setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(notificationStore.notifications)))).length / listPerPage))
            }
            setLoading(false)

            setCurrentPage(1);
        }
        fetchData()
            .catch(console.error);
    }, [])




    useEffect(() => {
        if (notificationStore.notifications) {
            setNotifications(JSON.parse(JSON.stringify(notificationStore.notifications)));
            setFilteredNotifications(JSON.parse(JSON.stringify(notificationStore.notifications)));
            setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(notificationStore.notifications)))).length / listPerPage))
        }

        setCurrentPage(1);
    }, [notificationStore.notifications])

    const handleFilter = () => {
        setIsThereData(true);
        setCurrentPage(1);
        let dts = notifications.filter((item) => {
            return vehicleDropdown == '' ? item : item.vehicleType.toLowerCase() == vehicleDropdown.toLowerCase();
        }).filter((item) => {
            return title?.toLocaleLowerCase('tr-TR') == '' ? item : item.title?.toLocaleLowerCase('tr-TR').includes(title.toLocaleLowerCase('tr-TR'));
        }).filter((item) => {
            return profile == '' ? item : item.profile.toLowerCase() == profile.toLowerCase();
        })
        setFilteredNotifications(dts);
        setCurrentPage(1);
        setPageCount(Math.ceil(dts.length / listPerPage))
        if (dts.length == 0) {
            toast.error("Girdiğiniz kriterlere ait kayıt bulunamamıştır.");
            setIsThereData(false)
        }

    }

    const clearFilters = () => {
        handleVehicleDropdown('');
        setTitle('');
        setProfile('')
        setCurrentPage(1);
        setFilteredNotifications(notifications);
        setPageCount(Math.ceil(notifications.length / listPerPage))
        setIsThereData(true);
    }

    const handleVehicleDropdown = (value: any) => {
        setVehicleDropdown(value.toString());
    }



    function createElements() {
        var elements = [];
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == 1 ? 'none' : 'block' }} className="icon item" key={0} onClick={() => changePage(currentPage - 1)}><i className="left chevron icon"></i></a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 2 ? 'none' : 'block' }} className="item" key={1} onClick={() => changePage(currentPage - 2)}>{currentPage - 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 1 ? 'none' : 'block' }} className="item" key={2} onClick={() => changePage(currentPage - 1)}>{currentPage - 1}</a>);
        elements.push(<a style={{ cursor: 'pointer' }} className="item active" key={3} onClick={() => changePage(currentPage)}>{currentPage}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 1 > pageCount ? 'none' : 'block' }} className="item" key={4} onClick={() => changePage(currentPage + 1)}>{currentPage + 1}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 2 > pageCount ? 'none' : 'block' }} className="item" key={5} onClick={() => changePage(currentPage + 2)}>{currentPage + 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == pageCount ? 'none' : 'block' }} className="icon item" key={6} onClick={() => changePage(currentPage + 1)}><i className="right chevron icon"></i></a>);
        return elements;
    }

    const changePage = async (newPage: number) => {
        setCurrentPage(newPage);
    }

    const closeDetailNotificationModal = () => {
        setDetailNotificationModal(false);
    }
    const closeCreateNotificationModal = () => {
        setCreateNotificationModal(false);
    }

    const handleDetail = (id: string) => {
        setElementId(id);
        setDetailNotificationModal(true);
    }

    const handleSetSearch = (result: any) => {
        setTitle(result)
        setCurrentPage(1);
    }


    if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

    return (
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

                <div className='ApproverBodyContainer' style={{ width: '100%' }}>
                    <ModalInnerContainer width={'600px'} body={<CreateNotification handleClose={closeCreateNotificationModal} />} modalTitle='Manuel Bildirim Ekleme' open={createNotificationModal} closeModal={closeCreateNotificationModal} />
                    <ModalInnerContainer width={'600px'} body={<DetailNotification handleClose={closeDetailNotificationModal} elementId={elementId} />} modalTitle='Bildirim Detayları' open={detailNotificationModal} closeModal={closeDetailNotificationModal} />

                    <div className="" style={{ background: 'white' }}>
                        <div className="searchFilter">
                            <div className="notificationSearch" style={{ width: '180px', marginRight: '15px' }} >
                                <p>Başlık Ara</p>

                                <div className="ui category search" style={{ width: '180px' }}>
                                    <div className="ui icon input" style={{ width: '180px ' }} >
                                        <input className="prompt" style={{ background: '#F2F2F2' }} type="text" placeholder="Ara" onChange={(event) => handleSetSearch(event.target.value)} value={title}></input>
                                        <i className="search icon"></i>
                                    </div>
                                    <div className="results"></div>
                                </div>
                            </div>
                            <div className="vehicleDropdown " style={{ width: '180px', marginRight: '15px' }} >
                                <p>Ürün Grubu</p>
                                <Dropdown style={{
                                    width: '180px',
                                    backgroundColor: 'rgb(242,242,242)',
                                    height: '43px',
                                    margin: '0 auto',
                                    fontSize: '16px',
                                }}
                                    placeholder='Seçiniz'
                                    fluid
                                    selection
                                    options={[{ key: 'truck', text: "Kamyon", value: "Kamyon" },
                                    { key: 'bus', text: "Otobüs", value: "Otobüs" },
                                    ]}
                                    value={vehicleDropdown}
                                    onChange={(event, { value }) => {
                                        if (value != null) {
                                            setVehicleDropdown(value.toString());
                                        }
                                    }}
                                />
                            </div>
                            <div className="titleDropdown " style={{ width: '250px', marginRight: '15px' }} >
                                <p>Profil</p>
                                <Dropdown style={{
                                    width: '250px',
                                    backgroundColor: 'rgb(242,242,242)',
                                    height: '43px',
                                    margin: '0 auto',
                                    fontSize: '16px',
                                }}
                                    placeholder='Başlık Seçiniz.'
                                    fluid
                                    selection
                                    options={[{ key: 'all', text: "Tüm Kullanıcılar", value: "A" },
                                    { key: 'determined', text: "Belirli Kullanıcılar", value: "D" },
                                    ]}
                                    value={profile}
                                    onChange={(event, { value }) => {
                                        if (value != null) { setProfile(value.toString()); }
                                    }}
                                />
                            </div>
                            <div className="filterButtonWrapper" style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                                <input className='button history-button' onClick={() => { handleFilter() }} type='submit' value='Ara' />
                                <input style={{ marginLeft: '8px' }} className='button  return-button' onClick={() => { clearFilters() }} type='submit' value='Temizle' />
                                <input style={{ marginLeft: '8px' }} className='button   confirm-button' onClick={() => { setCreateNotificationModal(true); }} type='submit' value='Ekle' />
                            </div>
                        </div>

                        {!isThereData ?
                            <div style={{ background: 'white', textAlign: 'center', padding: '150px 0' }}>
                                <h1>Girdiğiniz kriterlere ait kayıt bulunamamıştır.</h1>
                                <input style={{ margin: 'auto' }} className='button  close-button' onClick={() => { clearFilters() }} type='submit' value='Geri Dön' />

                            </div>
                            :
                            <div className="dataTable">

                                <table style={{ padding: ' 15px 0 0 0 ' }}>
                                    <thead style={{ background: '#32C5FF' }}>
                                        <tr>
                                            <th>Ürün Grubu</th>
                                            <th>Başlık</th>
                                            <th>Gönderilme Tarihi</th>
                                            <th>Durum</th>
                                            <th>Profil</th>
                                            <th>Detay</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredNotifications
                                            .slice((currentPage - 1) * listPerPage, currentPage * listPerPage)
                                            .map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.vehicleType} </td>
                                                    <td>{item.title}</td>
                                                    <td>
                                                        {
                                                            item.dateToSend && new Date(item.dateToSend).getTime() !== new Date(Number.MIN_VALUE).getTime() ?
                                                                new Date(item.dateToSend).toLocaleString('tr-TR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false }).replace(/\./g, '/')
                                                                : ''
                                                        }
                                                    </td>
                                                    <td>{
                                                    item.status == "A" ? "Aktif" :
                                                    item.status == "P"  ? "Pasif" :
                                                    item.status == "S" ? "Seçili" :
                                                    item.status == "C" ? "Tamamlandı" :
                                                    item.status == "F" ? "Başarısız" :''}</td>
                                                    <td>{item.profile == "D" ? 'Belirli Kullanıcılar' : 'Tüm Kullanıcılar'}</td>
                                                    <td className="" style={{ padding: '0', width: '60px' }}>
                                                        <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleDetail((item.id).toString()) }} ><i style={{ margin: '0px 0px 4px 0px', color: 'rgb(70,170,250)' }} className="info list-icon icon"></i></button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                    {pageCount <= 1 ?
                        <div style={{ float: 'right', margin: '0.8rem', height: '40px' }}></div>
                        :
                        <div className="ui pagination menu" style={{ float: 'right', margin: '0.8rem' }} >{createElements()}</div>
                    }
                </div>
            </div >
        </>
    )
})