import { makeAutoObservable } from "mobx";
import { CancelMarketingDTO, NotificationDTO, NotificationDetailDTO, RemoveNotificationDTO } from "../models/models";
import agent from "../api/agent";

export default class NotificationStore {

    notifications: NotificationDTO | undefined = undefined;

    modalLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }

    loadNotifications = async () => {
        try {
            const result = await agent.Notification.list();
            if (result) {
                this.notifications = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;
        } catch (error) {
            //console.log(error);
            return false;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    delete = async (notification: RemoveNotificationDTO) => {
        try {
            var res = await agent.Notification.delete(notification);
            return res;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }




    getNotificationDetail = async (id: string): Promise<NotificationDetailDTO | null> => {
        try {
            const result = await agent.Notification.detail(id);
            return result;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }


    
    createNotification = async (notification: FormData) => {
        try {
            return await agent.Notification.create(notification);
        } catch (error) {
            //console.log(error);
            return false;
        }
    }
 
    excelExport = async (postUrl: string, postId: string, filename: string) => {
        try {
            const result = await agent.Notification.excelExport(postUrl, postId, filename);
            return result;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

}