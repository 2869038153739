import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useStore } from '../../app/stores/store';
import { toast } from 'react-toastify'
import InputErrorMessage from '../components/inputErrorMessage';
import LoadingComponent from '../../app/layout/LoadingComponent';
import ReactDatePicker from 'react-datepicker';
import { tr } from 'date-fns/locale';
import { Tab } from 'semantic-ui-react'
import UpdateStarPoints from './UpdateStarPoints';
import StarPointsLeaderboards from './StarPointsLeaderboards';

interface Props {
  handleClose: any;
  elementId: string;
}

export default observer(function StarPointModal(props: Props) {
  const { starPointStore } = useStore();
  const { updateStarPoint } = starPointStore;

  const [loading, setLoading] = useState<boolean>(false);

  const panes = [
    { menuItem: 'Detay Ekranı', render: () => <Tab.Pane attached={false}> <UpdateStarPoints handleClose={props.handleClose} elementId={props.elementId} /> </Tab.Pane>, },
    { menuItem: 'Puan Sıralaması', render: () => <Tab.Pane attached={false}><StarPointsLeaderboards handleClose={props.handleClose} elementId={props.elementId} /></Tab.Pane> },
  ]

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const data = await starPointStore.getStarPointDetail(props.elementId);
  //     if (data) {
  //       setLoading(true)
  //       setDailyInvitationPoint(data.dailyInvitationPoint)
  //       setDailyLoginPoint(data.dailyLoginPoint)
  //       setDailyMaxInvitationCount(data.dailyMaxInvitationCount)
  //       setDailyMaxLoginCount(data.dailyMaxLoginCount)
  //       setDailyMaxPostCount(data.dailyMaxPostCount)
  //       setDailyPostPoint(data.dailyPostPoint)
  //       setDescription(data.description)
  //       setInvitationAcceptancePoint(data.invitationAcceptancePoint)
  //       let sdate = new Date(data.startDate)
  //       let fdate = new Date(data.finishDate)
  //       setFinishDate(fdate);
  //       setStartDate(sdate);
  //     }
  //   }
  //   fetchData()
  //     .catch(console.error);
  // }, [])

  if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      </div >
    </>
  )
})