import { makeAutoObservable } from "mobx";
import { CancelMarketingDTO, DeleteRestingPlaceDTO, DetailMarketingDTO, MarketingDTO, RestingPlaceDTO, RestingPlaceListDTO } from "../models/models";
import agent from "../api/agent";

export default class AppStateStore {


    modalLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }

    getAppState = async () => {
        try {
            const result = await agent.AppState.getAppState();
            return result
        } catch (error) {
            //console.log(error);
            return error;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }



    setAppState = async (state: boolean) => {
        try {
            if (state) {
                var res = await agent.AppState.setAppStateOnline(state);
                return res;
            }
            else {
                var res = await agent.AppState.setAppStateOffline(state);
                return res;
            }
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }

    }

}