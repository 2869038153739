import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useStore } from '../../app/stores/store';
import { toast } from 'react-toastify'
import InputErrorMessage from '../components/inputErrorMessage';
import LoadingComponent from '../../app/layout/LoadingComponent';
import ReactDatePicker from 'react-datepicker';
import { tr } from 'date-fns/locale';
import { Tab } from 'semantic-ui-react'

interface Props {
  handleClose: any;
  elementId: string;
}

export default observer(function UpdateStarPoints(props: Props) {
  const { starPointStore } = useStore();
  const { updateStarPoint } = starPointStore;

  const [loading, setLoading] = useState<boolean>(false);
  const [isStartDateError, setIsStartDateError] = useState<boolean>(false);
  const [isPeriodEnded, setIsPeriodEnded] = useState<boolean>(false);
  const [isFinishDateError, setIsFinishDateError] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>('');
  const [finishDate, setFinishDate] = useState<any>('');
  const [dailyInvitationPoint, setDailyInvitationPoint] = useState<any>('');
  const [dailyLoginPoint, setDailyLoginPoint] = useState<any>('');
  const [dailyMaxInvitationCount, setDailyMaxInvitationCount] = useState<any>('');
  const [dailyMaxLoginCount, setDailyMaxLoginCount] = useState<any>('');
  const [dailyMaxPostCount, setDailyMaxPostCount] = useState<any>('');
  const [dailyPostPoint, setDailyPostPoint] = useState<any>('');
  const [description, setDescription] = useState<any>('');
  const [invitationAcceptancePoint, setInvitationAcceptancePoint] = useState<any>('');
  const [isDescriptionError, setIsDescriptionError] = useState<boolean>(false);
  const [isDatesCorrectError, setIsDatesCorrectError] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await starPointStore.getStarPointDetail(props.elementId);
      if (data) {
        setLoading(true);
        setDailyInvitationPoint(data.dailyInvitationPoint);
        setDailyLoginPoint(data.dailyLoginPoint);
        setDailyMaxInvitationCount(data.dailyMaxInvitationCount);
        setDailyMaxLoginCount(data.dailyMaxLoginCount);
        setDailyMaxPostCount(data.dailyMaxPostCount);
        setDailyPostPoint(data.dailyPostPoint);
        setDescription(data.description);
        setInvitationAcceptancePoint(data.invitationAcceptancePoint);
        let sdate = new Date(data.startDate);
        let fdate = new Date(data.endDate);
        let now = new Date();
        if (now > fdate) {
          setIsPeriodEnded(true);
        }
        setFinishDate(fdate);
        setStartDate(sdate);
        setLoading(false)

      }
    }
    fetchData()
      .catch(console.error);
  }, [])

  function CloseSubmit() {
    props.handleClose(false);
  }
  async function handleDate(date: any) {
    const newDate = new Date(date);
    let res = new Date(newDate.getTime() + (1000 * 60 * 60 * 3));
    return res;
  }
  async function handleFormSubmit() {
    try {
      setLoading(true);
      let errorExist: boolean = false;
      if (startDate.length <= 0) {
        setIsStartDateError(true)
        errorExist = true;
      }
      if (finishDate.length <= 0) {
        setIsFinishDateError(true)
        errorExist = true;
      }
      if (finishDate < startDate) {
        setIsDatesCorrectError(true)
        errorExist = true;
      }
      if (description.length <= 0) {
        setIsDescriptionError(true)
        errorExist = true;
      }
      if (errorExist) {
        return;
      }
      const formData = new FormData();

      if (true) {
        setLoading(true);
        let fdate = (await handleDate(finishDate)).toISOString()
        let sdate = (await handleDate(startDate)).toISOString()
        formData.append('Id', props.elementId);
        formData.append('Description', description);
        formData.append('StartDate', sdate);
        formData.append('EndDate', fdate);
        formData.append('DailyLoginPoint', dailyLoginPoint);
        formData.append('DailyMaxLoginCount', dailyMaxLoginCount);
        formData.append('DailyInvitationPoint', dailyInvitationPoint);
        formData.append('DailyMaxInvitationCount', dailyMaxInvitationCount);
        formData.append('InvitationAcceptancePoint', invitationAcceptancePoint);
        formData.append('DailyPostPoint', dailyPostPoint);
        formData.append('DailyMaxPostCount', dailyMaxPostCount);
        var result = await updateStarPoint(formData).then(() => {
          props.handleClose();
          toast.success('Periyot başarıyla güncellendi.')
        });
        await starPointStore.loadStarPoints();
        setLoading(false);
      }
    }
    catch (error) {
      //console.log(error);
    }
    finally {
      setLoading(false);
    }
  }


  if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>
      <div className="modalWrapper" style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
        <div className="" style={{ textAlign: 'center', marginBottom: '20px' }}>
          <InputErrorMessage inError={isDatesCorrectError} error='Başlangıç tarihi bitiş tarihinden büyük olamaz!' />
        </div>
        <div className="periodDates" style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div className="Startdate"  >
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Periyot Başlangıç Tarihi</div>
            <div style={{ marginBottom: '0px' }}>
            </div>
            <div className="sDates" style={{ display: 'flex', marginTop: '10px' }}>
              <div className="ui input icon" >
                <ReactDatePicker
                  autoComplete='off'
                  locale={tr}
                  dateFormat={'dd.MM.yyyy'}
                  name='StartDate'
                  placeholderText='gg.AA.YYYY'
                  selected={startDate}
                  disabled
                  onChange={(date) => {
                    setStartDate(date);
                    setIsStartDateError(false);
                    setIsDatesCorrectError(false);
                  }}
                  className="dateArea"
                />
                <i className="calendar icon" ></i>
              </div>

            </div>
          </div>
          <div className="Enddate"  >
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Periyot Bitiş Tarihi</div>
            <div >
              <InputErrorMessage inError={isFinishDateError} error='Bu alan boş bırakılamaz!' />
            </div>
            <div className="eDates" style={{ display: 'flex', marginTop: '10px' }}>
              <div className="ui input icon" >
                <ReactDatePicker
                  autoComplete='off'
                  locale={tr}
                  dateFormat={'dd.MM.yyyy'}
                  name='StartDate'
                  placeholderText='gg.AA.YYYY'
                  selected={finishDate}
                  disabled={isPeriodEnded}
                  onChange={(date) => {
                    setFinishDate(date);
                    setIsFinishDateError(false);
                    setIsDatesCorrectError(false);
                  }}
                  className="dateArea"
                />
                <i className="calendar icon" ></i>
              </div>
            </div>
          </div>
        </div>
        <div className="dataTable" >
          <table style={{ padding: ' 20px' }}>
            <thead style={{ background: '#32C5FF' }}>
              <tr>
                <th>No</th>
                <th>Puan Maddeleri</th>
                <th>Günlük Limit</th>
                <th>Puan</th>
              </tr>
            </thead>
            <tbody>
              <tr >
                <td>1</td>
                <td>İnteraktif paylaşımlarda gönderi paylaştığında</td>
                <td>
                  <div >
                    <input type="number"
                      disabled={isPeriodEnded}
                      onChange={(e => { setDailyMaxPostCount(e.target.value) })}
                      placeholder={dailyMaxPostCount}
                      className="pointInput"
                      style={{ width: '65px' }} />
                  </div>
                </td>
                <td>
                  <div >
                    <input type="number"
                      disabled={isPeriodEnded}
                      onChange={(e => { setDailyPostPoint(e.target.value) })}
                      placeholder={dailyPostPoint}
                      className="pointInput"
                      style={{ width: '65px' }} />
                  </div>
                </td>
              </tr>
              <tr >
                <td>2</td>
                <td>Uygulamaya giriş yapıldığında</td>
                <td>
                  <div >
                    <input type="number"
                      disabled={isPeriodEnded}
                      onChange={(e => { setDailyMaxLoginCount(e.target.value) })}
                      placeholder={dailyMaxLoginCount}
                      className="pointInput"
                      style={{ width: '65px' }} />
                  </div>
                </td>
                <td>
                  <div >
                    <input type="number"
                      disabled={isPeriodEnded}
                      onChange={(e => { setDailyLoginPoint(e.target.value) })}
                      placeholder={dailyLoginPoint}
                      className="pointInput"
                      style={{ width: '65px' }} />
                  </div>
                </td>
              </tr>
              <tr >
                <td>3</td>
                <td>Davet ettiği arkadaşı uygulamaya kayıt olduğunda</td>
                <td>

                </td>
                <td>
                  <div >
                    <input type="number"
                      disabled={isPeriodEnded}
                      onChange={(e => { setInvitationAcceptancePoint(e.target.value) })}
                      placeholder={invitationAcceptancePoint}
                      className="pointInput"
                      style={{ width: '65px' }} />
                  </div>
                </td>
              </tr>
              <tr >
                <td>4</td>
                <td>Arkadaşını davet ettiğinde</td>
                <td>
                  <div >
                    <input type="number"
                      disabled={isPeriodEnded}
                      onChange={(e => { setDailyMaxInvitationCount(e.target.value) })}
                      placeholder={dailyMaxInvitationCount}
                      className="pointInput"
                      style={{ width: '65px' }} />
                  </div>
                </td>
                <td>
                  <div >
                    <input type="number"
                      disabled={isPeriodEnded}
                      onChange={(e => { setDailyInvitationPoint(e.target.value) })}
                      placeholder={dailyInvitationPoint}
                      className="pointInput"
                      style={{ width: '65px' }} />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="inputWrap" style={{ padding: '0 20px', width: '100%' }}>
          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Ödüllendirme Açıklama Metni </div>
          <InputErrorMessage inError={isDescriptionError} error='Bu alan boş bırakılamaz!' />
          <textarea name="" style={{ margin: '10px 0', width: '100%', height: '72px', resize: 'none' }} disabled={isPeriodEnded} id=""
            onChange={(event) => { setDescription(event.target.value); setIsDescriptionError(false); }} value={(description == null) ? '' : description} />
        </div>
        <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px ', marginBottom: '20px', float: 'right' }}>
          <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit(); }} type='submit' value='Vazgeç' />
          {isPeriodEnded ? <></> :
            <input className='button mr20 confirm-button' onClick={() => { handleFormSubmit(); }} type='submit' value='Güncelle' />
          }
        </div>
      </div>

    </>
  )
})