import { makeAutoObservable } from "mobx";
import { DeleteStarPointDTO, LeaderboardList, StarPointDetailDTO, StarPointListDTO } from "../models/models";
import agent from "../api/agent";

export default class StarPointStore {

    starPoints: StarPointListDTO | undefined = undefined;
    leaderboards: LeaderboardList | undefined = undefined;

    modalLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }

    loadStarPoints = async () => {
        try {
            const result = await agent.StarPoint.list();
            if (result) {
                this.starPoints = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;
        } catch (error) {
            //console.log(error);
            return false;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    getStarPointDetail = async (id: string): Promise<StarPointDetailDTO | null> => {
        try {
            const result = await agent.StarPoint.detail(id);
            return result;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    updateStarPoint = async (starPoint: FormData) => {
        try {
            return await agent.StarPoint.update(starPoint);
        } catch (error) {
            //console.log(error);
            return false;
        }

    }

    loadLeaderboards = async (id: string) => {
        try {
            const result = await agent.StarPoint.leaderboards(id);
            if (result) {
                this.leaderboards = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;
        } catch (error) {
            //console.log(error);
            return false;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }
}