import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Confirm, Dropdown } from 'semantic-ui-react';
import { toast } from 'react-toastify'
import { useStore } from '../../../app/stores/store';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { history } from "../../..";
import { CancelMarketingDTO } from '../../../app/models/models';

interface Props {
  handleClose: any;
  elementId: string;
  isAlreadyCancelled: boolean
}

export default observer(function DetailProduct(props: Props) {
  const { productStore } = useStore();
  const [title, setTitle] = useState('');
  const [order, setOrder] = useState('');
  const [contentType, setContentType] = useState('');
  const [thumbnail, setThumbnail] = useState<File>();
  const [videoFileName, setVideoFileName] = useState('');
  const [imageFileName, setImageFileName] = useState('');
  const [thumbnailFileName, setThumbnailFileName] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedVideo, setSelectedVideo] = useState('');
  const [selectedThumbnail, setSelectedThumbnail] = useState('');
  const [productDropdown, setProductDropdown] = useState('');
  const [vehicleType, setVehicleType] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadConfirmModalOpen, setUploadConfirmModalOpen] = useState<boolean>(false);

  const [description, setDescription] = useState('');



  useEffect(() => {
    const fetchData = async () => {
      let data = null

      data = await productStore.getProductDetail(props.elementId);

      if (data) {
        setTitle(data.title);
        setProductDropdown(data.vehicleType == 'Bus' ? 'Otobüs' : 'Kamyon');
        setOrder(data.order);
        setContentType(data.contentType);
        if (data.contentType == 'image') {
          setImageFileName(data.fileUrl)
          setSelectedImage(data.fileUrl)
        }

        else if (data.contentType == 'video') {
          setVideoFileName(data.fileUrl);
          setSelectedVideo(data.fileUrl);
          setThumbnailFileName(data.imageUrl);
          setSelectedThumbnail(data.imageUrl);
        }



        setVehicleType(data.vehicleType == 'Bus' ? true : false);
        setDescription(data.description);
      }

    }
    fetchData()
      .catch(console.error);
  }, [])

  function CloseSubmit() {
    props.handleClose(false);
  }

  const handleThumbnail = (e: any) => {
    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      const file = e.target.files![0];
      if (file && file.type.substring(0, 5) === "image") {
        setThumbnail(file);
        setThumbnailFileName(filename)
        setSelectedThumbnail(URL.createObjectURL(e.target.files[0]));
      }

    }
  }

  const handleCancel = () => {
    setCancelConfirmModalOpen(false);
  }
  const [cancelConfirmModalOpen, setCancelConfirmModalOpen] = useState<boolean>(false);
  const [elementId, setElementId] = useState<string>('');

  const handleRemove = (id: string) => {
    setElementId(id);
    setCancelConfirmModalOpen(true);
  }


  async function confirmRemoveSubmit() {
    try {
      let rid = new CancelMarketingDTO();
      rid.id = elementId;
      var res = await productStore.delete(rid);
      if (res === 200) {
        setCancelConfirmModalOpen(false);
        toast.success('Ürün başarıyla kaldırıldı.')
        props.handleClose();
        await productStore.loadProducts();
      }
      else {
        toast.error("İşlem sırasında bir hata meydana geldi. Lütfen tekrar deneyiniz. ")
      }
    }
    catch (error) {
      //console.log(error);
    }
  }




  if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>

      <Confirm className='RemoveConfirmModal'
        open={cancelConfirmModalOpen}
        content="Ürünü kaldırmak istiyor musunuz?"
        cancelButton='Vazgeç'
        confirmButton="Tamam"
        onCancel={handleCancel}
        onConfirm={() => { confirmRemoveSubmit(); }}
        size='mini'
      />

      <div className="modalWrapper" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="topWrap" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="inputWrap" style={{ padding: '0 20px', width: '80%' }}>
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Haber Başlık </div>
            <input disabled type="text" name="" style={{ margin: '10px 0', width: '100%' }} id=""
              onChange={(event) => { setTitle(event.target.value); }} value={(title == null) ? '' : title} />
          </div>
          <div className="productDropdown " style={{ width: '180px', marginRight: '15px' }} >
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0', }}>Ürün Grubu</div>
            <div className="" style={{ height: '10px' }}>
              <Dropdown style={{
                width: '180px',
                backgroundColor: 'rgb(242,242,242)',
                height: '36px',
                margin: '9px 0',
              }}
                placeholder='Seçiniz'
                fluid
                selection
                disabled
                options={[{ key: 'truck', text: "Kamyon", value: "Kamyon" },
                { key: 'bus', text: "Otobüs", value: "Otobüs" },
                ]}
                value={productDropdown}
                onChange={(event, { value }) => {
                  if (value != null) {
                    setProductDropdown(value.toString());
                  }
                }}
              />

            </div>

          </div>
        </div>
        <div className="inputWrap" style={{ padding: '0 20px', width: '100%' }}>
          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Haber Açıklama </div>
          <textarea disabled name="" style={{ margin: '10px 0', width: '100%', height: '72px', resize: 'none' }} id=""
            onChange={(event) => { setDescription(event.target.value); }} value={(description == null) ? '' : description} />
        </div>


        <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', margin: '10px 0', flexDirection: 'column' }}>
          <div style={{ marginLeft: '175px' }}>
          </div>
          <label htmlFor="picInput"
            style={{ height: '40px', width: '300px', display: 'flex', flexDirection: 'row', margin: 'auto', pointerEvents: 'none' }}
            className="btn ">
            <div className="entryModalButton dark-gray-button">
              Yüklenen Dosya
              <i style={{ margin: '0 5px ' }} className="file icon"></i>
              <input className='button dark-gray-button'
                id='picInput'
                style={{ height: '40px', width: '250px', color: 'white', visibility: 'hidden' }}
                type='file'
                accept="image/*,video/mp4"
              />
            </div>
          </label>

          {selectedImage ?
            <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column', marginLeft: '50px' }}>
              <div className="selectedBorderBox" style={{ display: 'flex', justifyContent: 'center', padding: '0px 20px 5px 20px ', flexDirection: 'column' }}>
                <div className="selectedFileDesc" style={{ padding: '10px' }}>
                </div>
                <img alt="preview image" style={{ maxHeight: '220px' }} src={selectedImage} />
                <a href={imageFileName} className="selectedFileName" style={{ textAlign: 'center', padding: '5px' }}> Resmi İndir</a>

              </div>

            </div>
            : <div />}
          {selectedVideo != '' ?

            <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column', marginLeft: '50px' }}>
              <div className="selectedBorderBox" >
                <div className="selectedFileDesc" style={{ padding: '10px' }}>
                  <video width="256" height="220" controls>
                    <source src={selectedVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>

                </div>



              </div>
            </div>
            : <div />}

          {selectedVideo != '' &&
            <div className="thumbWrapper" style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', margin: '10px 0', flexDirection: 'column' }} >
              <div style={{ marginLeft: '155px' }}>
              </div>
              <label htmlFor="thumbInput"
                style={{ height: '40px', width: '300px', display: 'flex', flexDirection: 'row', margin: 'auto', pointerEvents: 'none' }}
                className="btn">
                <div className="entryModalButton dark-gray-button">
                  Yüklenen Thumbnail
                  <i style={{ margin: '0 5px ' }} className="picture icon"></i>
                  <input className='button dark-gray-button'
                    id='thumbInput'
                    style={{ height: '40px', width: '250px', color: 'white', visibility: 'hidden' }}
                    onChange={(event) => { handleThumbnail(event); }}
                    type='file'
                    accept="image/*,video/mp4"
                  />
                </div>
              </label>
              {selectedThumbnail ?
                <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <div className="selectedBorderBox" style={{ display: 'flex', justifyContent: 'center', padding: '0px 20px 5px 20px ', flexDirection: 'column', marginLeft: '105px' }}>
                    <div className="selectedFileDesc" style={{ padding: '10px' }}>
                      <div className="selectedFileName" >Dosya Adı : <b>
                        {thumbnailFileName.length > 19 ? thumbnailFileName.substring(0, 10) + '...' + thumbnailFileName.substring(thumbnailFileName.length - 5) : thumbnailFileName}</b></div>

                    </div>
                    <img alt="preview image" style={{ maxHeight: '220px' }} src={selectedThumbnail} />
                    <a href={thumbnailFileName} className="selectedFileName" style={{ textAlign: 'center', padding: '5px' }}> Resmi İndir</a>

                  </div>

                </div>
                : <div />}
            </div>
          }
        </div>




      </div>
      <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px ' }}>
        <div className="inputWrap" style={{ padding: '0 20px', width: '22%', float: 'left' }}>
          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Ürün Sırası </div>
          <input disabled type="number" name="" style={{ margin: '10px 0', width: '60%', marginLeft: '17px' }} id=""
            onChange={(event) => { setOrder(event.target.value); }} value={(order == null) ? '' : order} />
        </div>
        <input style={{ height: '36px', marginTop: '34px', float: 'right' }} className='button mr20 dark-gray-button' onClick={() => { CloseSubmit(); }} type='submit' value='Kapat' />
        {!props.isAlreadyCancelled &&
          <input style={{ height: '36px', marginTop: '34px', float: 'right' }} className='button mr20 reject-button' onClick={() => { handleRemove(props.elementId); }} type='submit' value='Kaldır' />
        }
      </div>
    </>
  )
})