import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import UserStore from "./userStore";
import IntroCardStore from './IntroCardStore';
import IntroVideoStore from './IntroVideoStore';
import IntroRecommendationStore from './IntroRecommendationStore';
import CommonIntroStore from "./CommonIntroStore";
import RestingPlaceStore from "./RestingPlaceStore";
import StoryStore from "./StoryStore";
import CampaignStore from "./CampaignStore";
import ProductStore from "./ProductStore";
import CompanyNewStore from "./CompanyNewStore";
import RepairShopStore from "./RepairShopStore";
import SystemNotificationStore from "./SystemNotificationStore";
import NotificationStore from './NotificationStore';
import AppStateStore from "./AppStateStore";
import ActivityStore from "./ActivityStore";
import StarPointStore from "./StarPointStore";

interface Store {
    commonStore: CommonStore;
    userStore: UserStore;
    modalStore: ModalStore;
    introCardStore: IntroCardStore;
    introVideoStore: IntroVideoStore;
    introRecommendationStore: IntroRecommendationStore;
    commonIntroStore: CommonIntroStore
    repairShopStore: RepairShopStore;
    restingPlaceStore: RestingPlaceStore;
    storyStore: StoryStore;
    campaignStore: CampaignStore;
    companyNewStore: CompanyNewStore;
    productStore: ProductStore;
    systemNotificationStore: SystemNotificationStore;
    notificationStore: NotificationStore;
    appStateStore: AppStateStore;
    activityStore: ActivityStore;
    starPointStore: StarPointStore;
    // errorStore:ErrorStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    introCardStore: new IntroCardStore(),
    introVideoStore: new IntroVideoStore(),
    introRecommendationStore: new IntroRecommendationStore(),
    commonIntroStore: new CommonIntroStore(),
    repairShopStore: new RepairShopStore(),
    restingPlaceStore: new RestingPlaceStore(),
    storyStore: new StoryStore(),
    campaignStore: new CampaignStore(),
    productStore: new ProductStore(),
    companyNewStore: new CompanyNewStore(),
    systemNotificationStore: new SystemNotificationStore(),
    notificationStore: new NotificationStore(),
    appStateStore: new AppStateStore(),
    activityStore: new ActivityStore(),
    starPointStore: new StarPointStore(),
    // errorStore:ErrorStore,
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}