import { makeAutoObservable } from "mobx";
import { CancelMarketingDTO, DeleteRepairShopDTO, RepairShopDTO, RepairShopDetailDTO } from "../models/models";
import agent from "../api/agent";

export default class RepairShopStore {

    repairShops: RepairShopDTO | undefined = undefined;

    modalLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }

    loadRepairShops = async () => {
        try {
            const result = await agent.RepairShops.list();
            if (result) {
                this.repairShops = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;
        } catch (error) {
            //console.log(error);
            return false;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    

    delete = async (req: CancelMarketingDTO) => {
        try {
            var res = await agent.RepairShops.delete(req);
            return res;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    getRepairShopDetail = async (id: string): Promise<RepairShopDetailDTO | null> => {
        try {
            const result = await agent.RepairShops.detail(id);
            return result;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    createRepairShop = async (repairShop: FormData) => {
        try {
            return await agent.RepairShops.create(repairShop);
        } catch (error) {
            //console.log(error);
            return false;
        }
    }

    updateRepairShop = async (repairShop: FormData) => {
        try {
            return await agent.RepairShops.update(repairShop);
        } catch (error) {
            //console.log(error);
            return false;
        }

    }
}