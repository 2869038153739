import { observer } from 'mobx-react-lite'
import { useState, useEffect } from 'react'
import { useStore } from '../../app/stores/store';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { Tab } from 'semantic-ui-react'
import { LeaderboardList } from '../../app/models/models';

interface Props {
  handleClose: any;
  elementId: string;
}

export default observer(function StarPointsLeaderboards(props: Props) {
  const { starPointStore } = useStore();

  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const listPerPage = 10;


  const [filteredLeaderboards, setFilteredLeaderboards] = useState<LeaderboardList[]>([]);
  const [leaderboards, setLeaderboards] = useState<LeaderboardList[]>([]);


  useEffect(() => {
    const fetchData = async () => {

      await starPointStore.loadLeaderboards(props.elementId);
      if (starPointStore.leaderboards) {
        setLeaderboards(JSON.parse(JSON.stringify(starPointStore.leaderboards)));
        setFilteredLeaderboards(JSON.parse(JSON.stringify(starPointStore.leaderboards)));
        setCurrentPage(1)
        setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(starPointStore.leaderboards)))).length / listPerPage))
      }
    }
    fetchData()
      .catch(console.error);
  }, [])

  function CloseSubmit() {
    props.handleClose(false);
  }

  function createElements() {
    var elements = [];
    elements.push(<a style={{ cursor: 'pointer', display: currentPage == 1 ? 'none' : 'block' }} className="icon item" key={0} onClick={() => changePage(currentPage - 1)}><i className="left chevron icon"></i></a>);
    elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 2 ? 'none' : 'block' }} className="item" key={1} onClick={() => changePage(currentPage - 2)}>{currentPage - 2}</a>);
    elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 1 ? 'none' : 'block' }} className="item" key={2} onClick={() => changePage(currentPage - 1)}>{currentPage - 1}</a>);
    elements.push(<a style={{ cursor: 'pointer' }} className="item active" key={3} onClick={() => changePage(currentPage)}>{currentPage}</a>);
    elements.push(<a style={{ cursor: 'pointer', display: currentPage + 1 > pageCount ? 'none' : 'block' }} className="item" key={4} onClick={() => changePage(currentPage + 1)}>{currentPage + 1}</a>);
    elements.push(<a style={{ cursor: 'pointer', display: currentPage + 2 > pageCount ? 'none' : 'block' }} className="item" key={5} onClick={() => changePage(currentPage + 2)}>{currentPage + 2}</a>);
    elements.push(<a style={{ cursor: 'pointer', display: currentPage == pageCount ? 'none' : 'block' }} className="icon item" key={6} onClick={() => changePage(currentPage + 1)}><i className="right chevron icon"></i></a>);
    return elements;
  }
  const changePage = async (newPage: number) => {
    setCurrentPage(newPage);
  }

  if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>
      <div className="modalWrapper" style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
        <div className="contentWrap">

          {filteredLeaderboards.length < 1 ?
            <div style={{ background: 'white', textAlign: 'center', padding: '150px 0' }}>
              <h1>Bu periyoda dair kayıt bulunamadı.</h1>
            </div>

            : <div className="dataTable" >
              <table style={{ padding: ' 15px 0 0 0 ' }}>
                <thead style={{ background: '#32C5FF' }}>
                  <tr>
                    <th>No</th>
                    <th>Periyot Tarihleri</th>
                    <th>User ID</th>
                    <th>Kullanıcı Adı</th>
                    <th>Toplam Puan</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredLeaderboards.slice((currentPage - 1) * listPerPage, currentPage * listPerPage)
                    .map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.periodDates}</td>
                        <td>{item.userId}</td>
                        <td>{item.userName}</td>
                        <td>{item.totalPoints}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>}
          {pageCount <= 1 ?
            <div style={{ float: 'right', margin: '0.8rem', height: '40px' }}></div>
            :
            <div className="ui pagination menu" style={{ float: 'right', margin: '0.8rem' }} >{createElements()}</div>
          }


        </div>
        <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px ', margin: '20px 0', float: 'right' }}>
          <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit(); }} type='submit' value='Kapat' />
        </div>
      </div>
    </>
  )
})