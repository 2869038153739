// import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import { useEffect, useState } from 'react';
import { OpenIdConfig } from '../../config';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingComponent from '../../app/layout/LoadingComponent';

export default observer(function LoginForm() {
    const { userStore } = useStore();
    const location = useLocation();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const backgroundImage = {
        Image: '../assets/placeholder.png',
    }

    useEffect(() => {
        const fetchData = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get("code");

            if (code) {
                setIsLoading(true);
                try {
                    let codeInfo = { code: code };
                    await userStore.openIdLogin(codeInfo);
                } catch (error) {
                    console.error('Login failed:', error);
                }
                setIsLoading(false);
            }
        };

        fetchData();
    }, [location.search]);

    const login = () => {
        const redirectUri = encodeURIComponent(OpenIdConfig.RedirectUri);
        const authUrl = `${OpenIdConfig.AuthUrl}?client_id=${OpenIdConfig.ClientId}&redirect_uri=${redirectUri}&response_type=code&scope=openid`;
        window.location.href = authUrl;
    };

    return (
        <>
            {isLoading ? <LoadingComponent content='Lütfen Bekleyin...' /> : <></>}
            <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/background.jpg'})` }}
                className='loginBackground'
            >
                <div className='loginContainer'>
                    <div>
                        <div className='loginFormContainer'>
                            <div className="loginFormBox">
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <span className='loginTitle'>Customer Portal Admin Ekranları</span>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ marginTop: '30px' }}>
                                        <input className='samlButton' onClick={() => { login() }} type='submit' value='Sign in with SAML' />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )

})