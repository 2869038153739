import { makeAutoObservable } from "mobx";
import { CancelMarketingDTO, DeleteRestingPlaceDTO, DetailMarketingDTO, MarketingDTO, RestingPlaceDTO, RestingPlaceListDTO } from "../models/models";
import agent from "../api/agent";

export default class StoryStore {

    stories: MarketingDTO | undefined = undefined;

    modalLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }

    loadStories = async () => {
        try {
            const result = await agent.Story.list();
            if (result) {
                this.stories = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;
        } catch (error) {
            //console.log(error);
            return false;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    cancelStory = async (story: CancelMarketingDTO) => {
        try {
            var res = await agent.Story.cancelStory(story);
            return res;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    exportStory = async (story: CancelMarketingDTO) => {
        try {
            var res = await agent.Story.exportStory(story);
            return res;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }


    getStoryDetail = async (id: string): Promise<DetailMarketingDTO | null> => {
        try {
            const result = await agent.Story.detail(id);
            return result;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }
    excelExport = async (postUrl: string, postId: string, filename: string) => {
        try {
            const result = await agent.Story.excelExport(postUrl, postId, filename);
            return result;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }



    createStory = async (story: FormData) => {
        try {
            return await agent.Story.createStory(story);
        } catch (error) {
            //console.log(error);
            return false;
        }
    }

    updateRestingPlace = async (restingPlace: FormData) => {
        try {
            return await agent.RestingPlaces.update(restingPlace);
        } catch (error) {
            //console.log(error);
            return false;
        }

    }
}