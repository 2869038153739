import { makeAutoObservable } from "mobx";
import { CancelMarketingDTO, SystemNotificationDTO, SystemNotificationDetailDTO, RemoveSystemNotificationDTO } from "../models/models";
import agent from "../api/agent";

export default class SystemNotificationStore {

    systemNotifications: SystemNotificationDTO | undefined = undefined;

    modalLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }

    loadSystemNotifications = async () => {
        try {
            const result = await agent.SystemNotification.list();
            if (result) {
                this.systemNotifications = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;
        } catch (error) {
            //console.log(error);
            return false;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    delete = async (systemNotification: RemoveSystemNotificationDTO) => {
        try {
            var res = await agent.SystemNotification.delete(systemNotification);
            return res;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }




    getSystemNotificationDetail = async (id: string): Promise<SystemNotificationDetailDTO | null> => {
        try {
            const result = await agent.SystemNotification.detail(id);
            return result;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }


    
    createSystemNotification = async (systemNotification: FormData) => {
        try {
            return await agent.SystemNotification.create(systemNotification);
        } catch (error) {
            //console.log(error);
            return false;
        }
    }

    updateSystemNotification = async (systemNotification: FormData) => {
        try {
            return await agent.SystemNotification.update(systemNotification);
        } catch (error) {
            //console.log(error);
            return false;
        }

    }
 

}