import { makeAutoObservable } from "mobx";
import { CancelMarketingDTO, ProductDTO, UpdateOrderDTO } from "../models/models";
import agent from "../api/agent";

export default class ProductStore {

    products: ProductDTO | undefined = undefined;

    modalLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }

    loadProducts = async () => {
        try {
            const result = await agent.Product.list();
            if (result) {
                this.products = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;
        } catch (error) {
            //console.log(error);
            return false;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    delete = async (product: CancelMarketingDTO) => {
        try {
            var res = await agent.Product.delete(product);
            return res;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }




    getProductDetail = async (id: string): Promise<ProductDTO | null> => {
        try {
            const result = await agent.Product.detail(id);
            return result;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }


    
    createProduct = async (product: FormData) => {
        try {
            return await agent.Product.createProduct(product);
        } catch (error) {
            //console.log(error);
            return false;
        }
    }

    updateProductOrder = async (productOrder: UpdateOrderDTO) => {
        try {
            var res = await agent.Product.updateOrder(productOrder);
            return res;
        } catch (error) {
            //console.log(error);
            return false;
        }

    }

    updateRetrofit = async (productOrder: FormData) => {
        try {
            var res = await agent.Product.updateRetrofit(productOrder);
            return res;
        } catch (error) {
            //console.log(error);
            return false;
        }

    }
}