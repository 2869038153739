import { useEffect, useState } from "react";
import { Confirm, Dropdown } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { toast } from 'react-toastify'
import ReactDatePicker from "react-datepicker";
import { tr } from 'date-fns/locale';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from '../../../app/stores/store';
import { MarketingDTO, UpdateOrderDTO } from '../../../app/models/models';
import ModalInnerContainer from "../../../app/common/modals/ModalInnerContainer";
import CreateCompanyNew from "./CreateCompanyNew";
import DetailCompanyNew from "./DetailCompanyNew";
import DetailCampaign from "../campaign/DetailCampaign";

export default observer(function CompanyNewIndex() {

    const { companyNewStore } = useStore();
    const [companyNews, setCompanyNews] = useState<MarketingDTO[]>([]);
    const [filteredCompanyNews, setFilteredCompanyNews] = useState<MarketingDTO[]>([]);

    const [createCompanyNewModal, setCreateCompanyNewModal] = useState<boolean>(false);
    const [detailCampaignModal, setDetailCampaignModal] = useState<boolean>(false);
    const [detailCompanyNewModal, setDetailCompanyNewModal] = useState<boolean>(false);
    const [isAlreadyCancelled, setIsAlreardCancelled] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [isThereData, setIsThereData] = useState<boolean>(true);

    const [status, setStatus] = useState('');
    const [productDropdown, setProductDropdown] = useState('');

    const [publishDate, setPublishDate] = useState<any>('');
    const [removeDate, setRemoveDate] = useState<any>('');

    const [elementId, setElementId] = useState<string>('');
    const [dataType, setDataType] = useState<string>('');
    const [elementType, setElementType] = useState<string>('');
    const [selectedOrder, setSelectedOrder] = useState('');
    const [selectedOrderId, setSelectedOrderId] = useState('');
    const [selectedOrderType, seteSelectedOrderType] = useState('');


    const [orderList, setOrderList] = useState<{ id: string, order: number; }[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const listPerPage = 5;
    const [removeConfirmModalOpen, setRemoveConfirmModalOpen] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            await companyNewStore.loadCompanyNews();
            if (companyNewStore.companyNews) {
                setCompanyNews(JSON.parse(JSON.stringify(companyNewStore.companyNews)));
                setFilteredCompanyNews(JSON.parse(JSON.stringify(companyNewStore.companyNews)));
                setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(companyNewStore.companyNews)))).length / listPerPage))
            }
            setLoading(false)

            setCurrentPage(1)
        }
        fetchData()
            .catch(console.error);
    }, [])


    useEffect(() => {
        if (companyNewStore.companyNews) {
            setCompanyNews(JSON.parse(JSON.stringify(companyNewStore.companyNews)));
            setFilteredCompanyNews(JSON.parse(JSON.stringify(companyNewStore.companyNews)));
            setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(companyNewStore.companyNews)))).length / listPerPage))
            clearFilters();
            //clearAllInputs();
        }
        setCurrentPage(1)
    }, [companyNewStore.companyNews])

    const showOrderButton = (id: string, show: boolean, event: any) => {
        const element = document.getElementById('btn-' + id);
        const tdElement = document.getElementById('td-' + id);
        if (show) {
            element!.style.display = 'block';
        }

        else {
            element!.style.display = 'none';
        };

        // console.log(event.target.getAttribute('data-id'))
        const input = event.target.getAttribute('data-id');

    }


    const handleFilter = () => {
        setIsThereData(true);
        setCurrentPage(1);
        let dts = companyNews

            .filter((item) => {
                return productDropdown == '' ? item : item.vehicleType.toLowerCase() == productDropdown.toLowerCase();

            }).filter((item) => {
                let cDate = new Date(parseInt(item.startDate.slice(6, 10)), parseInt(item.startDate.slice(3, 5)) - 1, parseInt(item.startDate.slice(0, 2)), parseInt(item.startDate.slice(11, 13)), parseInt(item.startDate.slice(14, 16)))
                let mDate = new Date(parseInt(item.finishDate.slice(6, 10)), parseInt(item.finishDate.slice(3, 5)) - 1, parseInt(item.finishDate.slice(0, 2)), parseInt(item.finishDate.slice(11, 13)), parseInt(item.finishDate.slice(14, 16)))
                let pDate = publishDate == undefined || publishDate == '' ? new Date(1970, 0, 0) : new Date(publishDate);
                let rDate = removeDate == undefined || removeDate == '' ? new Date(2100, 11, 30) : new Date(removeDate);

                return cDate >= pDate && mDate <= rDate;
            })
        setFilteredCompanyNews(dts);
        setCurrentPage(1);
        setPageCount(Math.ceil(dts.length / listPerPage))
        if (dts.length == 0) {
            toast.error("Girdiğiniz kriterlere ait kayıt bulunamamıştır.");
            setIsThereData(false)
        }

    }

    const clearFilters = () => {
        handleProductDropdown('');
        handleStatusDropdown('');
        setRemoveDate('');
        setPublishDate('');
        setCurrentPage(1);
        setIsThereData(true);
        setFilteredCompanyNews(companyNews);
        setPageCount(Math.ceil(companyNews.length / listPerPage))
    }


    const handleProductDropdown = (value: any) => {
        setProductDropdown(value.toString());
    }

    const handleStatusDropdown = (value: any) => {
        setStatus(value.toString());
    }


    function handleFieldChange(id: string, order: string) {
        const news: MarketingDTO[] = filteredCompanyNews ? JSON.parse(JSON.stringify(filteredCompanyNews)) : null;
        if (news) {
            const item = news.find(s => s.id === id);
            if (item) {
                item.order = parseInt(order);
            }

            setFilteredCompanyNews(JSON.parse(JSON.stringify(news)));
        }
    }


    function createElements() {
        var elements = [];
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == 1 ? 'none' : 'block' }} className="icon item" key={0} onClick={() => changePage(currentPage - 1)}><i className="left chevron icon"></i></a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 2 ? 'none' : 'block' }} className="item" key={1} onClick={() => changePage(currentPage - 2)}>{currentPage - 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 1 ? 'none' : 'block' }} className="item" key={2} onClick={() => changePage(currentPage - 1)}>{currentPage - 1}</a>);
        elements.push(<a style={{ cursor: 'pointer' }} className="item active" key={3} onClick={() => changePage(currentPage)}>{currentPage}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 1 > pageCount ? 'none' : 'block' }} className="item" key={4} onClick={() => changePage(currentPage + 1)}>{currentPage + 1}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 2 > pageCount ? 'none' : 'block' }} className="item" key={5} onClick={() => changePage(currentPage + 2)}>{currentPage + 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == pageCount ? 'none' : 'block' }} className="icon item" key={6} onClick={() => changePage(currentPage + 1)}><i className="right chevron icon"></i></a>);
        return elements;
    }

    const changePage = async (newPage: number) => {
        setCurrentPage(newPage);
    }



    const closeCreateCompanyNewModal = () => {
        setCreateCompanyNewModal(false);
    }
    const closeDetailCompanyNewModal = () => {
        setDetailCompanyNewModal(false);
    }
    const closeDetailCampaignModal = () => {
        setDetailCampaignModal(false);
    }
    async function handleOrder(id: string, type: string) {

        let rid = new UpdateOrderDTO();
        let item = filteredCompanyNews.find(s => s.id == id)
        if (item) {
            rid.id = id;
            rid.order = item.order;
            rid.type = type;
        }

        var res = await companyNewStore.updateProductOrder(rid);
        if (res == 200) {
            setRemoveConfirmModalOpen(false);
            toast.success('Sıralama başarıyla güncellendi.')
            await companyNewStore.loadCompanyNews();
        }
        else {
            toast.success('İşlem sırasında bir problem meydana geldi')
        }
    };


    const handleCancel = () => {
        setRemoveConfirmModalOpen(false);
    }
    const handleDetail = (id: string, dataType: string, cancelled: boolean) => {
        setElementId(id);
        setDataType(dataType)
        setIsAlreardCancelled(cancelled)
        if (dataType == 'Haber') {
            setDetailCompanyNewModal(true);
        }
        else {
            setDetailCampaignModal(true)

        }
    }
    if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

    return (
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

                <div className='ApproverBodyContainer' style={{ width: '100%' }}>
                    <ModalInnerContainer width={'600px'} body={<CreateCompanyNew handleClose={closeCreateCompanyNewModal} />} modalTitle='Haber' open={createCompanyNewModal} closeModal={closeCreateCompanyNewModal} />
                    <ModalInnerContainer width={'600px'} body={<DetailCompanyNew handleClose={closeDetailCompanyNewModal} elementId={elementId} isAlreadyCancelled={isAlreadyCancelled} dataType={dataType} />} modalTitle={dataType} open={detailCompanyNewModal} closeModal={closeDetailCompanyNewModal} />
                    <ModalInnerContainer width={'600px'} body={<DetailCampaign handleClose={closeDetailCampaignModal} elementId={elementId} isAlreadyCancelled={isAlreadyCancelled} />} modalTitle='Kampanya' open={detailCampaignModal} closeModal={closeDetailCampaignModal} />
                    <Confirm className='RemoveConfirmModal'
                        open={removeConfirmModalOpen}
                        content='Sırayı güncellemek istiyor musunuz?'
                        cancelButton='Vazgeç'
                        confirmButton="Güncelle"
                        onConfirm={() => handleOrder(selectedOrderId, selectedOrderType)}
                        onCancel={handleCancel}
                        size='mini'
                    />

                    <div className="" style={{ background: 'white' }}>
                        <div className="searchFilter">

                            <div className="productDropdown " style={{ width: '180px', marginRight: '15px' }} >
                                <p>Ürün Grubu</p>
                                <Dropdown style={{
                                    width: '180px',
                                    backgroundColor: 'rgb(242,242,242)',
                                    height: '43px',
                                    margin: '0 auto',
                                    fontSize: '16px',
                                }}
                                    placeholder='Seçiniz'
                                    fluid
                                    selection
                                    options={[{ key: 'truck', text: "Kamyon", value: "Kamyon" },
                                    { key: 'bus', text: "Otobüs", value: "Otobüs" },
                                    ]}
                                    value={productDropdown}
                                    onChange={(event, { value }) => {
                                        if (value != null) {
                                            setProductDropdown(value.toString());
                                        }
                                    }}
                                />
                            </div>

                            <div className="Startdate" style={{ display: 'flex', flexDirection: 'column' }} >
                                <p>Yayınlanma Tarih</p>
                                <div className="sDates" style={{ display: 'flex' }}>
                                    <div className="ui input icon" style={{ marginRight: '10px' }} >
                                        <ReactDatePicker
                                            autoComplete='off'
                                            locale={tr}
                                            dateFormat={'dd.MM.yyyy'}
                                            name='StartDate'
                                            placeholderText='gg.AA.YYYY'
                                            selected={publishDate}
                                            onChange={setPublishDate}
                                            className="dateArea"
                                        />
                                        <i className="calendar icon" ></i>
                                    </div>

                                </div>
                            </div>
                            <div className="Enddate" style={{ display: 'flex', flexDirection: 'column' }} >
                                <p>Kaldırılma Tarihi</p>
                                <div className="eDates" style={{ display: 'flex' }}>
                                    <div className="ui input icon" style={{ zIndex: '2' }} >
                                        <ReactDatePicker
                                            autoComplete='off'
                                            locale={tr}
                                            dateFormat={'dd.MM.yyyy'}
                                            name='StartDate'
                                            placeholderText='gg.AA.YYYY'
                                            selected={removeDate}
                                            onChange={setRemoveDate}
                                            className="dateArea"
                                        />
                                        <i className="calendar icon" ></i>
                                    </div>
                                </div>
                            </div>
                            <div className="filterButtonWrapper" style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                                <input className='button history-button' onClick={() => { handleFilter() }} type='submit' value='Ara' />
                                <input style={{ marginLeft: '8px' }} className='button  return-button' onClick={() => { clearFilters() }} type='submit' value='Temizle' />
                                <input style={{ marginLeft: '8px' }} className='button   confirm-button' onClick={() => { setCreateCompanyNewModal(true); }} type='submit' value='Ekle' />
                            </div>

                        </div>

                        {!isThereData ?
                            <div style={{ background: 'white', textAlign: 'center', padding: '150px 0' }}>
                                <h1>Girdiğiniz kriterlere ait kayıt bulunamamıştır.</h1>
                                <input style={{ margin: 'auto' }} className='button  close-button' onClick={() => { clearFilters() }} type='submit' value='Geri Dön' />

                            </div>
                            :

                            <div className="dataTable">
                                <table style={{ padding: ' 15px 0 0 0 ' }}>
                                    <thead style={{ background: '#32C5FF' }}>

                                        <tr>
                                            <th rowSpan={2}>Bölüm</th>
                                            <th rowSpan={2}>Ürün Grubu</th>
                                            <th rowSpan={2}>Başlık</th>
                                            <th rowSpan={2}>{dataType} Yayınlanma Tarihi</th>
                                            <th rowSpan={2}>{dataType} Kaldırma Tarihi</th>
                                            <th rowSpan={2}>Durum</th>
                                            <th rowSpan={2}>Sıralama</th>
                                            <th rowSpan={2}>Detay</th>
                                        </tr>


                                    </thead>
                                    <tbody>
                                        {filteredCompanyNews.slice((currentPage - 1) * listPerPage, currentPage * listPerPage)
                                            .map((item) => (
                                                <tr key={item.id}>

                                                    <td>{item.dataType}</td>
                                                    <td>{item.vehicleType}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.startDate}</td>
                                                    <td>{item.finishDate}</td>
                                                    <td>{item.status}</td>

                                                    <td
                                                        style={{ width: '195px' }}
                                                        id={'td-' + item.id}
                                                        onMouseEnter={e => { showOrderButton(item.id, true, e) }} onMouseLeave={e => { showOrderButton(item.id, false, e) }}
                                                    >
                                                        <div >
                                                            <input type="text" data-id={'input-' + item.id}
                                                                onChange={(e => { handleFieldChange(item.id, e.target.value) })}
                                                                value={item.order}
                                                                className="orderInput"
                                                                style={{ width: '50px' }} />
                                                            <button
                                                                className="confirm-button button"
                                                                style={{ display: 'none' }}
                                                                onClick={() => {
                                                                    setSelectedOrderId(item.id);
                                                                    seteSelectedOrderType(item.dataType);
                                                                    setRemoveConfirmModalOpen(true)
                                                                }} data-id={item.id}
                                                                id={'btn-' + item.id}>
                                                                Güncelle
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="" style={{ padding: '0', width: '60px' }}>
                                                        <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleDetail((item.id).toString(), item.dataType, item.status == 'Yayından kaldırıldı' || item.status == 'Süresi doldu' ? true : false) }} ><i style={{ margin: '0px 0px 4px 0px', color: 'rgb(70,170,250)' }} className="info list-icon icon"></i></button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        }


                    </div>
                    {pageCount <= 1 ?
                        <div style={{ float: 'right', margin: '0.8rem', height: '40px' }}></div>
                        :
                        <div className="ui pagination menu" style={{ float: 'right', margin: '0.8rem' }} >{createElements()}</div>
                    }
                </div>
            </div >
        </>
    )
})