import { useEffect, useState } from "react";
import { Confirm, Dropdown } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { toast } from 'react-toastify'
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from '../../../app/stores/store';
import { SystemNotificationDTO } from '../../../app/models/models';
import ModalInnerContainer from "../../../app/common/modals/ModalInnerContainer";
import { history } from "../../..";
import CreateSystemNotification from "./CreateSystemNotification";
import DetailSystemNotification from "./DetailSystemNotification";

export default observer(function SystemNotificationIndex() {

    const { systemNotificationStore } = useStore();
    const [systemNotifications, setSystemNotifications] = useState<SystemNotificationDTO[]>([]);
    const [filteredSystemNotifications, setFilteredSystemNotifications] = useState<SystemNotificationDTO[]>([]);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [isThereData, setIsThereData] = useState<boolean>(true);

    const [title, setTitle] = useState('');
    const [notificationType, setNotificationType] = useState('1');
    const [notificationTypeName, setNotificationTypeName] = useState('');
    const [vehicleDropdown, setVehicleDropdown] = useState('');
    
    const [detailSystemNotificationModal, setDetailSystemNotificationModal] = useState<boolean>(false);

    const [elementId, setElementId] = useState<string>('');

    const [search, setSearch] = useState('');

    const listPerPage = 5;
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            await systemNotificationStore.loadSystemNotifications();
            if (systemNotificationStore.systemNotifications) {
                setSystemNotifications(JSON.parse(JSON.stringify(systemNotificationStore.systemNotifications)));
                setFilteredSystemNotifications(JSON.parse(JSON.stringify(systemNotificationStore.systemNotifications)));
                setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(systemNotificationStore.systemNotifications)))).length / listPerPage))
            }
            setLoading(false)
            setCurrentPage(1);
        }
        fetchData()
            .catch(console.error);
    }, [])




    useEffect(() => {
        if (systemNotificationStore.systemNotifications) {
            setSystemNotifications(JSON.parse(JSON.stringify(systemNotificationStore.systemNotifications)));
            setFilteredSystemNotifications(JSON.parse(JSON.stringify(systemNotificationStore.systemNotifications)));
            setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(systemNotificationStore.systemNotifications)))).length / listPerPage))
        }

        setCurrentPage(1);
    }, [systemNotificationStore.systemNotifications])

    const handleFilter = () => {
        setCurrentPage(1);
        let dts = systemNotifications.filter((item) => {
            return vehicleDropdown == '' ? item : item.vehicleType.toLowerCase() == vehicleDropdown.toLowerCase();
        })
        // .filter((item) => {
        //     return title?.toLocaleLowerCase('tr-TR') == '' ? item : item.title?.toLocaleLowerCase('tr-TR').includes(title.toLocaleLowerCase('tr-TR'));
        // }).filter((item) => {
        //     return section == '' ? item : item.section.toLowerCase() == section.toLowerCase();
        // })
        setFilteredSystemNotifications(dts);
        setCurrentPage(1);
        setPageCount(Math.ceil(dts.length / listPerPage))
        if (dts.length == 0) {
            toast.error("Girdiğiniz kriterlere ait kayıt bulunamamıştır.");
            setIsThereData(false)
        }

    }

    const clearFilters = () => {
        handleVehicleDropdown('');
        setTitle('');
        setNotificationTypeName('1')
        setCurrentPage(1);
        setFilteredSystemNotifications(systemNotifications);
        setPageCount(Math.ceil(systemNotifications.length / listPerPage))
        setIsThereData(true);
    }

    const handleVehicleDropdown = (value: any) => {
        setVehicleDropdown(value.toString());
    }



    function createElements() {
        var elements = [];
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == 1 ? 'none' : 'block' }} className="icon item" key={0} onClick={() => changePage(currentPage - 1)}><i className="left chevron icon"></i></a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 2 ? 'none' : 'block' }} className="item" key={1} onClick={() => changePage(currentPage - 2)}>{currentPage - 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 1 ? 'none' : 'block' }} className="item" key={2} onClick={() => changePage(currentPage - 1)}>{currentPage - 1}</a>);
        elements.push(<a style={{ cursor: 'pointer' }} className="item active" key={3} onClick={() => changePage(currentPage)}>{currentPage}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 1 > pageCount ? 'none' : 'block' }} className="item" key={4} onClick={() => changePage(currentPage + 1)}>{currentPage + 1}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 2 > pageCount ? 'none' : 'block' }} className="item" key={5} onClick={() => changePage(currentPage + 2)}>{currentPage + 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == pageCount ? 'none' : 'block' }} className="icon item" key={6} onClick={() => changePage(currentPage + 1)}><i className="right chevron icon"></i></a>);
        return elements;
    }

    const changePage = async (newPage: number) => {
        setCurrentPage(newPage);
    }

    const closeDetailSystemNotificationModal = () => {
        setDetailSystemNotificationModal(false);
    }
  

    const handleDetail = (id: string) => {
        setElementId(id);
        setDetailSystemNotificationModal(true);
    }



    if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

    return (
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

                <div className='ApproverBodyContainer' style={{ width: '100%' }}>
                    <ModalInnerContainer width={'500px'} body={<DetailSystemNotification handleClose={closeDetailSystemNotificationModal} elementId={elementId} />} modalTitle='Bildirim Detayları' open={detailSystemNotificationModal} closeModal={closeDetailSystemNotificationModal} />

                    <div className="" style={{ background: 'white' }}>
                        <div className="searchFilter">
                        
                            <div className="vehicleDropdown " style={{ width: '180px', marginRight: '15px' }} >
                                <p>Ürün Grubu</p>
                                <Dropdown style={{
                                    width: '180px',
                                    backgroundColor: 'rgb(242,242,242)',
                                    height: '43px',
                                    margin: '0 auto',
                                    fontSize: '16px',
                                }}
                                    placeholder='Seçiniz'
                                    fluid
                                    selection
                                    options={[{ key: 'truck', text: "Kamyon", value: "Kamyon" },
                                    { key: 'bus', text: "Otobüs", value: "Otobüs" },
                                    ]}
                                    value={vehicleDropdown}
                                    onChange={(event, { value }) => {
                                        if (value != null) {
                                            setVehicleDropdown(value.toString());
                                        }
                                    }}
                                />
                            </div>
                            <div className="sectionDropdown " style={{ width: '320px', marginRight: '15px' }} >
                                <p>Profil</p>
                                <Dropdown style={{
                                    width: '320px',
                                    backgroundColor: 'rgb(242,242,242)',
                                    height: '43px',
                                    margin: '0 auto',
                                    fontSize: '16px',
                                }}
                                    placeholder='Başlık Seçiniz.'
                                    fluid
                                    selection
                                    
                                    options={[{ key: 'all', text: "Standart Garantisi Biten Araçlar", value: "1" },
                                    ]}
                                    value={notificationType}
                                    onChange={(event, { value,text }) => {
                                        if (value != null) { 
                                            setNotificationType(value.toString());
                                            setNotificationTypeName(text!.toString());
                                         }
                                    }}
                                />
                            </div>
                            <div className="filterButtonWrapper" style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                                <input className='button history-button' onClick={() => { handleFilter() }} type='submit' value='Ara' />
                                <input style={{ marginLeft: '8px' }} className='button  return-button' onClick={() => { clearFilters() }} type='submit' value='Temizle' />
                            </div>
                        </div>

                        {!isThereData ?
                            <div style={{ background: 'white', textAlign: 'center', padding: '150px 0' }}>
                                <h1>Girdiğiniz kriterlere ait kayıt bulunamamıştır.</h1>
                                <input style={{ margin: 'auto' }} className='button  close-button' onClick={() => { clearFilters() }} type='submit' value='Geri Dön' />

                            </div>
                            :
                            <div className="dataTable">

                                <table style={{ padding: ' 15px 0 0 0 ' }}>
                                    <thead style={{ background: '#32C5FF' }}>
                                        <tr>
                                            <th>Ürün Grubu</th>
                                            <th>Başlık</th>
                                            <th>Bölüm</th>
                                            <th>Durum</th>
                                            <th>Detay</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredSystemNotifications
                                            .slice((currentPage - 1) * listPerPage, currentPage * listPerPage)
                                            .map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.vehicleType}</td>
                                                    <td>{item.title}</td>
                                                    <td>{item.notificationTypeName}</td>
                                                    <td>{item.status == "P" ? 'Pasif' : 'Aktif'}</td>
                                                    <td className="" style={{ padding: '0', width: '60px' }}>
                                                        <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleDetail((item.id).toString()) }} ><i style={{ margin: '0px 0px 4px 0px', color: 'rgb(70,170,250)' }} className="info list-icon icon"></i></button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                    {pageCount <= 1 ?
                        <div style={{ float: 'right', margin: '0.8rem', height: '40px' }}></div>
                        :
                        <div className="ui pagination menu" style={{ float: 'right', margin: '0.8rem' }} >{createElements()}</div>
                    }
                </div>
            </div >
        </>
    )
})