import { useEffect, useState } from "react";
import { Confirm, Dropdown } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { StarPointDetailDTO, DeleteStarPointDTO, StarPointListDTO } from '../../app/models/models';
import LoadingComponent from "../../app/layout/LoadingComponent";
import ModalInnerContainer from "../../app/common/modals/ModalInnerContainer";
import { toast } from 'react-toastify'
import StarUpdatePoints from "./UpdateStarPoints";
import ReactDatePicker from "react-datepicker";
import { tr } from 'date-fns/locale';
import StarPointModal from "./StarPointModal";

export default observer(function PointsIndex() {
    const { starPointStore } = useStore();
    const [starPoints, setStarPoints] = useState<StarPointListDTO[]>([]);
    const [filteredStarPoints, setFilteredStarPoints] = useState<StarPointListDTO[]>([]);
    const [search, setSearch] = useState('');
    const [finishDate, setFinishDate] = useState<any>('');
    const [startDate, setStartDate] = useState<any>('');
    const [vehicleType, setVehicleType] = useState<any>('');
    const [vehicleDropdown, setVehicleDropdown] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(true);


    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const listPerPage = 5;

    const [removeConfirmModalOpen, setRemoveConfirmModalOpen] = useState<boolean>(false);
    const [createStarPointModal, setCreateStarPointModal] = useState<boolean>(false);
    const [starPointModal, setUpdateStarPointModal] = useState<boolean>(false);

    const [elementId, setElementId] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            await starPointStore.loadStarPoints();
            if (starPointStore.starPoints) {
                setStarPoints(JSON.parse(JSON.stringify(starPointStore.starPoints)));
                setFilteredStarPoints(JSON.parse(JSON.stringify(starPointStore.starPoints)));
                setCurrentPage(1)
                setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(starPointStore.starPoints)))).length / listPerPage))
            }
            setLoading(false)
        }
        fetchData()
            .catch(console.error);
    }, [])

    useEffect(() => {
        if (starPointStore.starPoints) {
            setStarPoints(JSON.parse(JSON.stringify(starPointStore.starPoints)));
            setFilteredStarPoints(JSON.parse(JSON.stringify(starPointStore.starPoints)));
            setCurrentPage(1)
            setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(starPointStore.starPoints)))).length / listPerPage))
        }
    }, [starPointStore.starPoints])

    const handleFilter = () => {

        var rp = starPoints.filter((item) => {
            return vehicleDropdown == '' ? item
                : vehicleDropdown == 'Kamyon' ? item.vehicleType?.toLowerCase() == 'truck'
                    : vehicleDropdown == 'Otobüs' && item.vehicleType?.toLowerCase() == 'bus'
        })
        setCurrentPage(1);
        setPageCount(Math.ceil(rp.length / listPerPage))
        setFilteredStarPoints(rp);
        if (rp.length == 0) {
            toast.error("Girdiğiniz kriterlere ait kayıt bulunamamıştır.");
        }
    }

    const clearFilters = () => {
        setCurrentPage(1);
        setFilteredStarPoints(starPoints);
        setPageCount(Math.ceil(starPoints.length / listPerPage));
        setSearch('');
        setStartDate('');
        setFinishDate('');
    }

    const closeCreateStarPointModal = () => {
        setCreateStarPointModal(false);
    }

    const closeStarPointModal = () => {
        setUpdateStarPointModal(false);
    }





    function createElements() {
        var elements = [];
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == 1 ? 'none' : 'block' }} className="icon item" key={0} onClick={() => changePage(currentPage - 1)}><i className="left chevron icon"></i></a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 2 ? 'none' : 'block' }} className="item" key={1} onClick={() => changePage(currentPage - 2)}>{currentPage - 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 1 ? 'none' : 'block' }} className="item" key={2} onClick={() => changePage(currentPage - 1)}>{currentPage - 1}</a>);
        elements.push(<a style={{ cursor: 'pointer' }} className="item active" key={3} onClick={() => changePage(currentPage)}>{currentPage}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 1 > pageCount ? 'none' : 'block' }} className="item" key={4} onClick={() => changePage(currentPage + 1)}>{currentPage + 1}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 2 > pageCount ? 'none' : 'block' }} className="item" key={5} onClick={() => changePage(currentPage + 2)}>{currentPage + 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == pageCount ? 'none' : 'block' }} className="icon item" key={6} onClick={() => changePage(currentPage + 1)}><i className="right chevron icon"></i></a>);
        return elements;
    }

    const changePage = async (newPage: number) => {
        setCurrentPage(newPage);
    }

    const handleUpdate = (id: string) => {
        setElementId(id);
        setUpdateStarPointModal(true);
    }




    if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

    return (
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <div className='HeaderContainer' style={{ display: 'flex', height: '50px', alignItems: 'center', paddingLeft: '20px', background: '#32C5FF' }}>
                    <span className='BannerTitle'>YILDIZ PUAN</span>
                </div>
                <div className='defaultBodyContainer' style={{ width: '100%' }}>
                    <ModalInnerContainer width={'850px'} body={<StarPointModal elementId={elementId} handleClose={closeStarPointModal} />} modalTitle='Yıldız Puan' open={starPointModal} closeModal={closeStarPointModal} />


                    <div className="" style={{ background: 'white' }}>
                        <div className="searchFilter">
                            <div className="productDropdown " style={{ width: '180px', marginRight: '15px' }} >
                                <p>Ürün Grubu</p>
                                <Dropdown style={{
                                    width: '180px',
                                    backgroundColor: 'rgb(242,242,242)',
                                    height: '43px',
                                    margin: '0 auto',
                                    fontSize: '16px',
                                }}
                                    placeholder='Seçiniz'
                                    fluid
                                    selection
                                    options={[{ key: 'truck', text: "Kamyon", value: "Kamyon" },
                                    { key: 'bus', text: "Otobüs", value: "Otobüs" },
                                    ]}
                                    value={vehicleDropdown}
                                    onChange={(event, { value }) => {
                                        if (value != null) {
                                            setVehicleDropdown(value);
                                        }
                                    }}
                                />

                            </div>

                            <div className="filterButtonWrapper" style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                                <input className='button  history-button' onClick={() => { handleFilter() }} type='submit' value='Ara' />
                                <input style={{ marginLeft: '8px' }} className='button  return-button' onClick={() => { clearFilters() }} type='submit' value='Temizle' />
                            </div>

                        </div>

                        {filteredStarPoints.length < 1 ?
                            <div style={{ background: 'white', textAlign: 'center', padding: '150px 0' }}>
                                <h1>Girdiğiniz kriterlere ait kayıt bulunamamıştır.</h1>
                                <input style={{ margin: 'auto' }} className='button  close-button' onClick={() => { clearFilters() }} type='submit' value='Geri Dön' />
                            </div>

                            : <div className="dataTable" >
                                <table style={{ padding: ' 15px 0 0 0 ' }}>
                                    <thead style={{ background: '#32C5FF' }}>
                                        <tr>
                                            <th>No</th>
                                            <th>Periyot Tarihleri</th>
                                            <th>Ürün Grubu</th>
                                            <th>Durum</th>
                                            <th>Detay</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredStarPoints.slice((currentPage - 1) * listPerPage, currentPage * listPerPage)
                                            .map((item, index) => (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.periodDates}</td>
                                                    <td>{item.vehicleType == "Bus" ? "Otobüs" : "Kamyon"}</td>
                                                    <td>{item.status}</td>
                                                    <td className="" style={{ padding: '0', width: '60px' }}>
                                                        <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleUpdate((item.id).toString()) }} ><i style={{ margin: '0px 0px 4px 0px', color: 'rgb(200,200,200)' }} className="sync list-icon icon"></i></button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>}
                    </div>
                    {pageCount <= 1 ?
                        <div style={{ float: 'right', margin: '0.8rem', height: '40px' }}></div>
                        :
                        <div className="ui pagination menu" style={{ float: 'right', margin: '0.8rem' }} >{createElements()}</div>
                    }
                </div>
            </div >
        </>
    )
})