import { makeAutoObservable } from "mobx";
import { IntroCardDTO, IntroCardListDTO } from "../models/models";
import agent from "../api/agent";

export default class IntroCardStore {

    introCards: IntroCardListDTO | undefined = undefined;

    modalLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }
    loadIntroCards = async () => {
        try {
            const result = await agent.IntroCard.listCards();
            if (result) {
                this.introCards = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;
        } catch (error) {
            //console.log(error);
            return false;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    getIntroCardDetail = async (id: string): Promise<IntroCardDTO | null> => {
        try {
            const result = await agent.IntroCard.detail(id);
            return result;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    createCard = async (card: FormData) => {
        try {
            return await agent.IntroCard.createCard(card);
        } catch (error) {
            //console.log(error);
            return false;
        }
    }

    updateCard = async (card: FormData) => {
        try {
            return await agent.IntroCard.updateCard(card);
        } catch (error) {
            //console.log(error);
            return false;
        }

    }
}