import { makeAutoObservable } from "mobx";
import { DeleteActivityDTO, ActivityDTO, ActivityListDTO } from "../models/models";
import agent from "../api/agent";

export default class ActivityStore {

    activities: ActivityListDTO | undefined = undefined;

    modalLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }

    loadActivities = async () => {
        try {
            const result = await agent.Activity.list();
            if (result) {
                this.activities = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;
        } catch (error) {
            //console.log(error);
            return false;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    delete = async (req: DeleteActivityDTO) => {
        try {
            var res = await agent.Activity.delete(req);
            return res;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    getActivityDetail = async (id: string): Promise<ActivityDTO | null> => {
        try {
            const result = await agent.Activity.detail(id);
            return result;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    createActivity = async (activity: FormData) => {
        try {
            return await agent.Activity.create(activity);
        } catch (error) {
            //console.log(error);
            return false;
        }
    }

    updateActivity = async (activity: FormData) => {
        try {
            return await agent.Activity.update(activity);
        } catch (error) {
            //console.log(error);
            return false;
        }

    }
}