import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useStore } from '../../app/stores/store';
import { toast } from 'react-toastify'
import InputErrorMessage from '../components/inputErrorMessage';
import LoadingComponent from '../../app/layout/LoadingComponent';
import ReactDatePicker from 'react-datepicker';
import { tr } from 'date-fns/locale';

interface Props {
  handleClose: any;
}

export default observer(function CreateActivity(props: Props) {
  const { activityStore } = useStore();
  const { createActivity } = activityStore;

  const [activityName, setActivityName] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isNameError, setIsNameError] = useState<boolean>(false);
  const [isLocationError, setIsLocationError] = useState<boolean>(false);
  const [isImageError, setIsImageError] = useState<boolean>(false);
  const [selectedMainImage, setSelectedMainImage] = useState('');
  const [mainImageFileName, setMainImageFileName] = useState('');
  const [mainImage, setMainImage] = useState<File>();
  const [location, setLocation] = useState('');
  const [isStartDateError, setIsStartDateError] = useState<boolean>(false);
  const [isFinishDateError, setIsFinishDateError] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>('');
  const [finishDate, setFinishDate] = useState<any>('');
  const [isDatesCorrectError, setIsDatesCorrectError] = useState<boolean>(false);


  const [images, setImages] = useState<Array<string | null>>([]);
  const [selectedImages, setSelectedImages] = useState<Array<string | null>>([]);
  const [existingImages, setExistingImages] = useState<any>('');



  function CloseSubmit() {
    props.handleClose(false);
  }
  async function handleDate(date: any) {
    const newDate = new Date(date);
    let res = new Date(newDate.getTime() + (1000 * 60 * 60 * 3));
    return res;
  }
  async function handleFormSubmit() {
    try {
      setLoading(true);
      let errorExist: boolean = false;

      if (activityName.length <= 0) {
        setIsNameError(true)
        errorExist = true;
      }
      if (location.length <= 0) {
        setIsLocationError(true)
        errorExist = true;
      }
      if (startDate.length <= 0) {
        setIsStartDateError(true)
        errorExist = true;
      }
      if (finishDate.length <= 0) {
        setIsFinishDateError(true)
        errorExist = true;
      }
      if (finishDate < startDate) {
        setIsDatesCorrectError(true)
        errorExist = true;
      }
      if (errorExist) {
        return;
      }
      const formData = new FormData();

      if (true) {
        let fdate = (await handleDate(finishDate)).toISOString()
        let sdate = (await handleDate(startDate)).toISOString()
        formData.append('StartDate', sdate);
        formData.append('FinishDate', fdate);
        formData.append('Title', activityName);
        formData.append('Location', location);
        formData.append('ImageUrl', mainImage!);
        var nonNullImages = Object.entries(images)
          .filter(([key, value]) => value !== null)
          .map(([key, value]) => value);

        Object.entries(nonNullImages).forEach(([key, value], index) => {
          if (value !== null) {
            formData.append(`ActivityImages`, value);
          }
        });
        setLoading(true);
        var result = await createActivity(formData).then(() => {
          props.handleClose();
          toast.success('Etkinlik başarıyla eklendi.')
        });
        await activityStore.loadActivities();
        setLoading(false);
      }
    }
    catch (error) {
      //console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  const handleFile = (e: any) => {
    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      const file = e.target.files![0];

      if (file && file.type.substring(0, 5) === "image") {
        if (file.size > 5000000) {
          toast.error("Seçilen resmin boyutu 5MB'ın üzerindedir");
        }
        else {
          setMainImage(file);
          setMainImageFileName(filename)
          setSelectedMainImage(URL.createObjectURL(e.target.files[0]));
        }
      }

    }
  }

  const handleRemoveImage = (imageNumber: number) => {
    setImages(prevImages => {
      const newImages = Object.entries(prevImages).reduce((acc, [key, value], index) => {
        if (index !== imageNumber) {
          acc[key] = value;
        }
        return acc;
      }, {} as { [key: string]: string | null }); // Specify the type of newImages explicitly
      return Object.values(newImages);
    });

    setSelectedImages(prevImages => {
      const newImages = Object.entries(prevImages).reduce((acc, [key, value], index) => {
        if (index !== imageNumber) {
          acc[key] = value;
        }
        return acc;
      }, {} as { [key: string]: string | null }); // Specify the type of newImages explicitly
      return Object.values(newImages);
    });
  }


  const handleImageFile = (e: any) => {
    var imageIndex = 0;
    var BreakException = {};
    var isFlag = false;
    if (e.target.files.length > 0) {
      const img = e.target.files![0];
      try {
        Object.values(images).forEach(element => {
          if (!element) {
            if (img && img.type.substring(0, 5) === "image") {
              isFlag = true;
              setImages(prevState => ({
                ...prevState,
                [(imageIndex - 1)]: img
              }));
              setSelectedImages(prevState => ({
                ...prevState,
                [(imageIndex - 1)]: URL.createObjectURL(e.target.files[0])
              }));
            }
          }
          imageIndex++;
        });
        if (!isFlag) {
          setImages(prevState => ({
            ...prevState,
            [(imageIndex)]: img
          }));
          setSelectedImages(prevState => ({
            ...prevState,
            [(imageIndex)]: URL.createObjectURL(e.target.files[0])
          }));
        }
      } catch (e) {
        if (e !== BreakException) throw e;
      }

    }
  }


  if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>
      <div className="modalWrapper" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="topside" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="topside60" style={{ width: '60%', padding: '0 20px' }}>
            <div className="inputWrap">
              <div className='EntryHeader' style={{ textAlign: 'left', marginBottom: '3px', padding: '0 0 0.2rem  0' }}>Etkinlik Adı </div>
              <InputErrorMessage inError={isNameError} error='Bu alan boş bırakılamaz!' />
              <input type="text" maxLength={100} name="" style={{ margin: '10px 0', width: '100%' }} id=""
                onChange={(event) => { setActivityName(event.target.value); setIsNameError(false); }} value={(activityName == null) ? '' : activityName} />
            </div>
            <div className="" style={{ textAlign: 'center', marginBottom: '10px' }}>
              <InputErrorMessage inError={isDatesCorrectError} error='Başlangıç tarihi bitiş tarihinden büyük olamaz!' />
            </div>
            <div className="dates" style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="Startdate" style={{ display: 'flex', flexDirection: 'column', width: '45%' }} >
                <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Yayınlanma Tarihi</div>
                <div style={{ marginBottom: '0px' }}>
                  <InputErrorMessage inError={isStartDateError} error='Bu alan boş bırakılamaz!' />
                </div>
                <div className="sDates" style={{ display: 'flex', margin: '10px 0' }}>
                  <div className="ui input icon" style={{ width: '100%' }} >
                    <ReactDatePicker
                      locale={tr}
                      timeInputLabel="Time:"
                      dateFormat="dd/MM/yyyy HH:mm"
                      timeFormat='p'
                      showTimeInput
                      name='FinishDate'
                      placeholderText='gg.AA.YYYY SS:dd'
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        setIsStartDateError(false);
                        setIsDatesCorrectError(false);
                      }}
                      className="dateAreaStory"
                    />
                    <i className="calendar icon" ></i>
                  </div>
                </div>
              </div>
              <div className="Enddate" style={{ display: 'flex', flexDirection: 'column', marginLeft: '45px', width: '45%' }} >
                <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Kaldırılma Tarihi</div>
                <div style={{ marginBottom: '0px' }}>
                  <InputErrorMessage inError={isFinishDateError} error='Bu alan boş bırakılamaz!' />
                </div>
                <div className="eDates" style={{ display: 'flex', margin: '10px 0' }}>
                  <div className="ui input icon" style={{ width: '100%' }} >
                    <ReactDatePicker
                      locale={tr}
                      timeInputLabel="Time:"
                      dateFormat="dd/MM/yyyy HH:mm"
                      showTimeInput
                      name='StartDate'
                      timeFormat="HH:mm"
                      placeholderText='gg.AA.YYYY SS:dd'
                      selected={finishDate}
                      onChange={(date) => {
                        setFinishDate(date);
                        setIsFinishDateError(false);
                        setIsDatesCorrectError(false);
                      }}
                      className="dateAreaStory"
                    />
                    <i className="calendar icon" ></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="inputWrap">
              <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Etkinlik Konumu </div>
              <InputErrorMessage inError={isLocationError} error='Bu alan boş bırakılamaz!' />
              <input type="text" maxLength={100} name="" style={{ margin: '10px 0', width: '100%' }} id=""
                onChange={(event) => { setLocation(event.target.value); setIsLocationError(false); }} value={(location == null) ? '' : location} />
            </div>
          </div>
          <div className="topside40" style={{ width: '40%', marginRight: '20px' }}>
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Etkinlik Resmi </div>
            <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column' }}>
              <div style={{ marginLeft: '195px', marginBottom: '10px' }}>
              </div>
              <label htmlFor="picInput"
                style={{ height: '40px', width: '300px', display: 'flex', flexDirection: 'row', margin: 'auto' }}
                className="btn">
                <div className="entryModalButton dark-gray-button">
                  Resim Ekle
                  <i style={{ margin: '0 5px ' }} className="file icon"></i>
                  <input className='button dark-gray-button'
                    id='picInput'
                    style={{ height: '40px', width: '250px', color: 'white', visibility: 'hidden' }}
                    onChange={(event) => { handleFile(event); }}
                    type='file'
                    accept="image/*"
                  />
                </div>
              </label>
              {selectedMainImage ?
                <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column', marginLeft: '-69px' }}>
                  <div className="selectedBorderBox" style={{ display: 'flex', justifyContent: 'center', padding: '0px 20px 5px 20px ', flexDirection: 'column' }}>
                    <div className="selectedFileDesc" style={{ padding: '10px' }}></div>
                    <img alt="preview image" style={{ width: '220px', maxHeight: '220px', marginBottom: '10px' }} src={selectedMainImage} />
                  </div>
                </div>
                : <div />}
            </div>
          </div>
        </div>
        <div className="asd" style={{ display: 'flex', flexDirection: 'column', margin: '10px 0' }}>
          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Etkinlikten Kareler (Max. 6)</div>
          <div className="imageBox" style={{ display: 'flex', overflow: 'auto', marginTop: '10px' }}>
            {Object.values(selectedImages).map((image, index) => (
              image ? (
                <div key={index} className="selectedFileDesc" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '2px 0', margin: '10px ' }}>
                  <img alt="preview image" style={{ maxWidth: '220px', maxHeight: '220px' }} src={image} />
                  <input className='button  reject-button' style={{ maxWidth: '220px' }} onClick={() => { handleRemoveImage(index); }} type='submit' value='Kaldır' />
                </div>
              ) : null
            ))}
          </div>
          {Object.values(images).filter(image => image !== null).length <= 5 ?
            <label htmlFor="picInput1"
              style={{ height: '40px', display: 'flex', flexDirection: 'row', margin: '10px auto' }}
              className="btn">
              <div className="entryModalButton dark-gray-button">
                Resim Ekle
                <i style={{ margin: '0 5px ' }} className="picture icon"></i>
                <input className='button dark-gray-button'
                  id='picInput1'
                  style={{ height: '40px', width: '50px', color: 'white', visibility: 'hidden' }}
                  onChange={(event) => { handleImageFile(event); setIsImageError(false); }}
                  type='file'
                  accept="image/*"
                />
              </div>
            </label> : ''
          }
        </div>
      </div>
      <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px ', float: 'right' }}>
        <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit(); }} type='submit' value='Vazgeç' />
        <input className='button mr20 confirm-button' onClick={() => { handleFormSubmit(); }} type='submit' value='Ekle' />
      </div>
    </>
  )
})